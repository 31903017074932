import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject2ProjectBased } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest2Page = observer(() => {
  const { requestProjectStore } = useAppStore();
  const { projectRequest, solutionsSelectOptions } = requestProjectStore;

  React.useEffect(() => {
    requestProjectStore.preloadSolutions();
  }, [requestProjectStore]);

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject2ProjectBased
        solutionsOptions={solutionsSelectOptions}
        initial={projectRequest}
        onSend={requestProjectStore.secondStepProjectBased}
        goBack={requestProjectStore.goBack}
        promoReview={requestProjectStore.getPromoReview()}
      />
    </>
  );
});
