import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, Link } from 'react-router-dom';
import * as queryString from 'query-string';
import { useAppStore } from 'store/app-store.hook';
import { Loader } from 'components/Loader';
import { authRoutes } from 'routing';

export const AuthGoogle: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const location = useLocation();
  const { code } = queryString.parse(location.search);

  React.useEffect(() => {
    authStore.loginWithGoogle(code as string);
  }, [authStore, code]);

  return (
    <div>
      {!authStore.error ? (
        <div className="fadeIn loader-fixed">
          <Loader />
        </div>
      ) : (
        <div>
          <div>{authStore.error}</div>
          <div>
            <Link to={authRoutes.LOGIN}>Go to login</Link>
          </div>
        </div>
      )}
    </div>
  );
});
