import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-2';
import { StartProjectRequest1, PromoReview } from '../../../types/project-request-types';
import { validators, validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { Promo } from 'components/Promo';

type FormValues = StartProjectRequest1;

const validationSchema = () =>
  Yup.object()
    .shape({
      firstName: validators.firstName,
      lastName: validators.lastName,
      email: validators.email,
      phoneNumber: validators.phoneNumber,
      companyName: validators.companyName,
      websiteUrl: validators.websiteUrl.notRequired(),
    })
    .required();

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  websiteUrl: null,
};

interface StartProject1Props {
  error?: string;
  initial: FormValues;
  onSend: (params: FormValues) => void;
  promoReview: PromoReview;
}

export const StartProject1: React.FC<StartProject1Props> = ({ onSend, initial, error, promoReview }) => {
  const onPhoneChange = (number: string) => {
    setFieldTouched('phoneNumber', true);
    setFieldValue('phoneNumber', number, true);
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...initial,
    },
    onSubmit: onSend,
    validate: validate<FormValues>(validationSchema),
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = formik;
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-progress-line">
          <div className="auth-progress-value step-1"></div>
        </div>
        <div className="auth-card card-wide">
          <Form onSubmit={handleSubmit}>
            <div className="auth-box">
              <div className="auth-headline">
                <strong className="auth-logo">
                  <Logo />
                </strong>
                <h1>Let’s get started.</h1>
                <p>To help better understand your business goals, we’d like to ask you a few questions.</p>
              </div>
              <Row>
                <Col sm={6}>
                  <FormGroup
                    className={classNames(
                      'form-group-addon',
                      { 'input-active': values.firstName },
                      { 'input-invalid': touched.firstName && !!errors.firstName },
                      { 'input-touched': touched.firstName },
                      { 'input-valid': touched.firstName && !errors.firstName },
                    )}
                  >
                    <label htmlFor="firstName">First Name*</label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      valid={touched.firstName && !errors.firstName}
                      invalid={touched.firstName && !!errors.firstName}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <FormFeedback>{errors.firstName}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup
                    className={classNames(
                      'form-group-addon',
                      { 'input-active': values.lastName },
                      { 'input-invalid': touched.lastName && !!errors.lastName },
                      { 'input-touched': touched.lastName },
                      { 'input-valid': touched.lastName && !errors.lastName },
                    )}
                  >
                    <label htmlFor="lastName">Last Name*</label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      valid={touched.lastName && !errors.lastName}
                      invalid={touched.lastName && !!errors.lastName}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <FormFeedback>{errors.lastName}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup
                className={classNames(
                  'form-group-addon',
                  { 'input-active': values.email },
                  { 'input-invalid': touched.email && !!errors.email },
                  { 'input-touched': touched.email },
                  { 'input-valid': touched.email && !errors.email },
                )}
              >
                <label htmlFor="email">Email*</label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email address"
                  autoComplete="email"
                  valid={touched.email && !errors.email}
                  invalid={touched.email && !!errors.email}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <i className="glyphicon glyphicon-user form-control-feedback" />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
              <FormGroup
                className={classNames(
                  'form-group-addon',
                  { 'input-active': values.phoneNumber },
                  { 'input-invalid': touched.phoneNumber && !!errors.phoneNumber },
                  { 'input-touched': touched.phoneNumber },
                  { 'input-valid': touched.phoneNumber && !errors.phoneNumber },
                )}
              >
                <label>Phone number</label>
                <ReactPhoneInput
                  containerClass={classNames(
                    'react-tel-input form-control',
                    { 'is-active': values.phoneNumber },
                    { 'is-invalid': touched.phoneNumber && !!errors.phoneNumber },
                    { 'is-touched': touched.phoneNumber },
                    { 'is-valid': touched.phoneNumber && !errors.phoneNumber },
                  )}
                  inputExtraProps={{
                    name: 'phone',
                    required: true,
                  }}
                  defaultCountry="us"
                  placeholder="(000) 000-0000"
                  value={values.phoneNumber}
                  onChange={onPhoneChange}
                />
                <i className="glyphicon glyphicon-user form-control-feedback" />
                <FormFeedback className="order-3">{errors.phoneNumber}</FormFeedback>
              </FormGroup>
              <Row>
                <Col sm={6}>
                  <FormGroup
                    className={classNames(
                      'form-group-addon',
                      { 'input-active': values.companyName },
                      {
                        'input-invalid': touched.companyName && !!errors.companyName,
                      },
                      { 'input-touched': touched.companyName },
                      { 'input-valid': touched.companyName && !errors.companyName },
                    )}
                  >
                    <label htmlFor="companyName">Company Name*</label>
                    <Input
                      type="text"
                      name="companyName"
                      id="companyName"
                      placeholder="Company Name"
                      valid={touched.companyName && !errors.companyName}
                      invalid={touched.companyName && !!errors.companyName}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName}
                    />
                    <FormFeedback>{errors.companyName}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup
                    className={classNames(
                      'form-group-addon',
                      { 'input-active': values.websiteUrl },
                      {
                        'input-invalid': touched.websiteUrl && !!errors.websiteUrl,
                      },
                      { 'input-touched': touched.websiteUrl },
                      { 'input-valid': touched.websiteUrl && !errors.websiteUrl },
                    )}
                  >
                    <label htmlFor="websiteUrl">Website URL</label>
                    <Input
                      type="text"
                      name="websiteUrl"
                      id="websiteUrl"
                      placeholder="Website"
                      valid={touched.websiteUrl && !errors.websiteUrl}
                      invalid={touched.websiteUrl && !!errors.websiteUrl}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.websiteUrl || ''}
                    />
                    <FormFeedback>{errors.websiteUrl}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <p className="c-gray fs-12 mb-0">
                We’re committed to your privacy. MindTrust uses the information you provide to us to contact you about
                our relevant content, products, and services. You may unsubscribe from these communications at any time.
                For more information, check out our Privacy Policy.
              </p>
            </div>
            <div className="auth-actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup className="d-flex justify-content-center">
                <Button className="btn-block" type="submit" color="primary" disabled={!isValid} onClick={handleSubmit}>
                  Next<i className="btn-icon-next"></i>
                </Button>
              </FormGroup>
            </div>
            <p className="help-text">
              Need help? <NavLink to="#">Speak with a Sales Consultant.</NavLink>
            </p>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">
        <Promo {...promoReview} />
      </div>
    </div>
  );
};
