import { Group, Category } from '../types';
import _ from 'lodash';

interface Cell {
  value: string | number;
  category: string | null;
  group: string | null;
}

type Grid = Cell[][];

const groupToRow = (group: Group): Cell[] => [{ value: group.name, category: null, group: group.name }];

const categoryToRow = (category: Category, group: string, columns: string[]): Cell[] => [
  { value: category.name, group, category: category.name },
  ...columns.map((col) => ({
    value: category.costs[col],
    category: category.name,
    group,
  })),
];

export const groupsToGrid = (group: Group[], columns: string[]): Grid => {
  return group.flatMap((group) => {
    return [groupToRow(group), ...group.categories.map((c) => categoryToRow(c, group.name, columns))];
  });
};

const isGroupRow = (cell: Cell) => !!cell.group && !cell.category;
const isCategoryRow = (cell: Cell) => !!cell.category;

export const gridToGroups = (grid: Grid, columns: string[]): Group[] => {
  const res = grid.flatMap((row) => {
    if (isGroupRow(row[0])) {
      return {
        group: row[0].group,
      };
    } else if (isCategoryRow(row[0])) {
      return {
        group: row[0].group,
        category: {
          name: row[0].category || '',
          costs: row.slice(1).reduce((cos: any, next, index) => {
            if (next.value) {
              cos[columns[index]] = next.value;
            }
            return cos;
          }, {}),
        },
      };
    }
  });
  const groupped = _.groupBy(_.compact(res), ({ group }) => group);
  return Object.keys(groupped).flatMap((name) => {
    const gk = groupped[name];
    return {
      name,
      categories: gk.flatMap((k) => k?.category || []),
    };
  });
};
