import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../store/app-store.hook';
import { RegistrationForm } from '../../components/Auth/RegistrationForm';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { RegisterRequest } from 'types/auth-types';

export const VerifyInvitePage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const { search } = useLocation();
  const { access_token = '' } = queryString.parse(search);
  const token = access_token as string;
  const decoded = authStore.decodeToken(token);
  console.log('decoded', decoded);
  React.useEffect(() => {
    if (decoded.userExists) authStore.verifyInvite(token, {});
  }, [access_token, authStore, decoded.userExists, token]);

  const onRegister = ({ email, ...values }: RegisterRequest) => authStore.verifyInvite(token, { register: values });

  if (decoded.userExists) return null;
  return (
    <>
      <Helmet>
        <title>Verify Invite | MindTrust</title>
      </Helmet>
      <div>
        <RegistrationForm email={decoded.email} onRegister={onRegister} error={authStore.error} />
      </div>
    </>
  );
});
