import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { expensesRoutes } from '../../routing';
import { useAppStore } from 'store/app-store.hook';
import { MyExpensesPage } from './MyExpensesPage';
import { TeamExpensesPage } from './TeamExpensesPage';

export const ExpensesRoutes: React.FC = observer(() => {
  const { expenses } = useAppStore().permissionStore;
  return (
    <Switch>
      {expenses.myExpenses && <Route exact path={expensesRoutes.MY_EXPENSES} component={MyExpensesPage} />}
      {expenses.teamExpenses && <Route exact path={expensesRoutes.TEAM_EXPENSES} component={TeamExpensesPage} />}
    </Switch>
  );
});
