import { observable, computed, action } from 'mobx';
import { PermissionStore } from '../permisson-store/permission-store';
import styles from './navigation.module.scss';
import { permissionToTab, permissionToTabNew } from './permissions-to-tab';
import { RouterStore } from 'mobx-react-router';

export interface NavItem {
  name: string;
  url: string;
  icon: string;
  children?: NavItem[];
}

export class NavigationStore {
  @observable private _permissionStore: PermissionStore;
  @observable private routerStore: RouterStore;
  @observable private _selected: NavItem | null = null;

  constructor(permissionStore: PermissionStore, routerStore: RouterStore) {
    this._permissionStore = permissionStore;
    this.routerStore = routerStore;
  }

  @computed
  get selected() {
    return this._selected;
  }

  @action
  setSelected = (selected: NavItem) => {
    this._selected = selected;
    const firstChild = selected.children && selected.children[0];
    firstChild ? this.routerStore.push(firstChild.url) : this.routerStore.push(selected.url);
  };

  @computed
  get navigation(): { items: NavItem[] } {
    const items = [this.dashboard];
    if (this.time.children.length) items.push(this.time);
    if (this.companies.children.length) items.push(this.companies);
    if (this.contacts.children.length) items.push(this.contacts);
    if (this.projects.children.length) items.push(this.projects);
    if (this.expenses.children.length) items.push(this.expenses);
    if (this.referrals.children.length) items.push(this.referrals);
    if (this.billing.children.length) items.push(this.billing);
    if (this.subscriptions.children.length) items.push(this.subscriptions);
    if (this.documents.children.length) items.push(this.documents);
    if (this.globalSettings.children.length) items.push(this.globalSettings);
    return {
      items,
    };
  }

  @computed
  private get dashboard() {
    return {
      name: 'Dashboard',
      url: '/',
      icon: `${styles.icon_dashboard} ${styles.nav_icon}`,
    };
  }

  @computed
  private get time() {
    return {
      name: 'Time',
      url: '/time',
      icon: `${styles.icon_time} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.time),
    };
  }

  @computed
  private get companies() {
    return {
      name: 'Companies',
      url: '/companies',
      icon: `${styles.icon_companies} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.companies),
    };
  }

  @computed
  private get contacts() {
    return {
      name: 'Contacts',
      url: '/contacts',
      icon: `${styles.icon_contacts} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.contacts),
    };
  }

  @computed
  private get projects() {
    return {
      name: 'Projects',
      url: '/projects',
      icon: `${styles.icon_projects} ${styles.nav_icon}`,
      children: permissionToTabNew(this._permissionStore.projects),
    };
  }

  @computed
  private get expenses() {
    return {
      name: 'Expenses',
      url: '/my-expenses',
      icon: `${styles.icon_expenses} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.expenses),
    };
  }

  @computed
  private get referrals() {
    return {
      name: 'Referrals',
      url: '/referrals',
      icon: `${styles.icon_referrals} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.referrals),
    };
  }

  @computed
  private get billing() {
    return {
      name: 'Billing',
      url: '/billing',
      icon: `${styles.icon_billing} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.billing),
    };
  }

  @computed
  private get subscriptions() {
    return {
      name: 'Subscriptions',
      url: '/subscriptions',
      icon: `${styles.icon_subscriptions} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.subscriptions),
    };
  }

  @computed
  private get documents() {
    return {
      name: 'Documents',
      url: '/documents',
      icon: `${styles.icon_documents} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.documents),
    };
  }

  @computed
  private get globalSettings() {
    return {
      name: 'Global Settings',
      url: '/#',
      icon: `${styles.icon_settings} ${styles.nav_icon}`,
      children: permissionToTab(this._permissionStore.globalSettings),
    };
  }
}
