import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useAppStore } from '../../store/app-store.hook';
import { RegistrationForm } from '../../components/Auth/RegistrationForm';

export const RegistrationPage: React.FC = observer(() => {
  const { authStore } = useAppStore();

  return (
    <>
      <Helmet>
        <title>Registration | MindTrust</title>
      </Helmet>
      <div>
        <RegistrationForm onRegister={authStore.register} error={authStore.error} />
      </div>
    </>
  );
});
