import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import { data } from '../../utils/pie-data';

import styles from './Pie.module.scss';
import './Pie.scss';

// callbacks: { label: (tooltipItem: any, data: any, index: number) => { return `${tooltipItem.value} Hello Chris! ${labels[index]}` } }

interface Props {
  value: number;
  title: string;
}

export const Pie: React.FC<Props> = ({ value, title }) => {
  return (
    <>
      <div className={styles.pie_holder}>
        <em className={styles.pie_total}>
          <strong>$8,523</strong> <em>Current Spend</em> as of 8/20 20:17 PM EDT
        </em>
        <Doughnut
          data={data}
          options={{
            legend: { display: false },
            cutoutPercentage: 90,
            rotation: 2.04,
            tooltips: {
              bodyFontFamily: "'avenir', Arial, Helvetica, sans-serif",
              bodyFontSize: 14,
              // bodyFontColor: "#2e353a",
              // backgroundColor: "rgba(255, 255, 255, .9)",
              // shadowOffsetX: 10,
              // shadowOffsetY: 25,
              // shadowBlur: 40,
              // shadowColor: "rgba(56, 87, 104, 0.3)",
              // cornerRadius: 0,
              // xPadding: 15,
              // yPadding: 9,
              // titleFontColor: "#f00",
              // callbacks: {
              // label: (tooltipItem: any, index: number) => `${setValues[index]}`,
              // title: (tooltipItem: any, index: number) => `${setDepartment[index]}`,
              //   afterLabel: function (tooltipItem: any, data: any) {
              //     return setValues[tooltipItem.datasetIndex]
              //   }
              //   label: function (tooltipItem: any, data: any) {
              //     return setLabels[tooltipItem.datasetIndex]
              //   }
              // labelColor: function () {
              //   return {
              //     borderColor: "rgb(255, 0, 0)",
              //     backgroundColor: "rgb(255, 0, 0)",
              //   };
              // },
              // },
              // displayColors: false,
            },
          }}
          width={214}
          height={214}
        />{' '}
        {/* rotation: 1.55 */}
        <strong className={styles.pie_value}>{value}</strong>
      </div>
      <em className={styles.pie_title}>
        {title}
      </em>
    </>
  );
};
