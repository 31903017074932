import React from 'react';
import styles from './Promo.module.scss';

interface Props {
  headline: string;
  stars: number;
  quote: string;
  avatar: string;
  author: string;
  company: string;
}

export const Promo: React.FC<Props> = ({ headline, stars, quote, avatar, author, company }) => {
  const rating = stars * 20;
  return (
    <div className={styles.promo}>
      <div className={styles.promo_headline}>
        <div className={styles.rating_holder}>
          <div className={styles.rating_stars} style={{ width: `${rating}%` }}></div>
        </div>
        <h2>{headline}</h2>
      </div>
      <div className={styles.promo_body}>
        <p>What our partners say</p>
        <blockquote className={styles.promo_blockquote}>
          <q>{quote}</q>
          <cite className={styles.promo_cite}>
            <img src={avatar} alt="" />
            <strong>{author}</strong>
            <em>{company}</em>
          </cite>
        </blockquote>
      </div>
    </div>
  );
};
