import React from 'react';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Loader } from 'components/Loader';
import { ProjectsTable } from 'components/ProjectsTable';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

export const AgencyResellersPage: React.FC = observer(() => {
  const { projectsStore, routerStore } = useAppStore();
  const { search } = routerStore.location;

  React.useEffect(() => {
    const { offset = 0, limit = 10 } = queryString.parse(search);
    projectsStore.loadProjects({ offset: Number(offset), limit: Number(limit) });
  }, [projectsStore, search]);

  return (
    <>
      <Helmet>
        <title>Companies | Agency Resellers</title>
      </Helmet>
      <div className="mlr-sm">
        {/* <Headline title="Agency Resellers" ctaLabel="Add New Agency" subTitle={`${projectsStore.projects.length} agencies`} menuItems={navigation} /> */}
      </div>
      {projectsStore.isLoading && (
        <div className="loader-fixed">
          <Loader />
        </div>
      )}
      <ProjectsTable
        total={projectsStore.total}
        projects={projectsStore.projects}
        onSearch={projectsStore.searchProjects}
        onFilter={projectsStore.applyFilters}
        onSort={projectsStore.sort}
      />
    </>
  );
});
