import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject5 } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest5Page = observer(() => {
  const { requestProjectStore } = useAppStore();
  const { projectRequest } = requestProjectStore;
  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject5
        initial={projectRequest}
        onSend={requestProjectStore.fifthStep}
        goBack={requestProjectStore.goBack}
        promoReview={requestProjectStore.getPromoReview()}
      />
    </>
  );
});
