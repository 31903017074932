import React from 'react';
import { Link } from 'react-router-dom';
import { Progress } from 'reactstrap';
import { projectsRoutes } from 'routing';
import { ProjectListEntity } from 'store/entities/project-list-entitiy';
import styles from './ProjectsTable.module.scss';

export const nameFormatter = (projects: ProjectListEntity[]) => (cell: string, _: any, __: any, rowIndex: number) => {
  return (
    <>
      <strong className={styles.project_name}>
        <Link to={projectsRoutes.PROJECT_OVERVIEW(projects[rowIndex].id)}>{cell}</Link>
      </strong>
      <em className={styles.project_title}>
        <Link className={styles.link_color_inherit} to={projectsRoutes.PROJECT_OVERVIEW('1')}>
          {projects[rowIndex].agency}
        </Link>{' '}
        |{' '}
        <Link className={styles.link_color_inherit} to={projectsRoutes.PROJECT_OVERVIEW('1')}>
          {projects[rowIndex].client}
        </Link>
      </em>
    </>
  );
};

export const typeFormatter = (cell: any) => (
  <>
    <i data-title={cell} className={`icon-${cell}`.toLowerCase().replace(' ', '-')}></i> {cell}
  </>
);

export const progressFormatter = (cell: number) => <Progress value={cell} />;

export const remainingFormatter = (projects: ProjectListEntity[]) => (cell: any, _: any, __: any, rowIndex: number) => (
  <>
    {cell} <span className={`ml-2 ${styles.percentage}`}>{projects[rowIndex].percentage}%</span>
  </>
);
