import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import styles from './ViewAs.module.scss';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';

export const ViewAs = observer(() => {
  const { search } = useLocation();
  const { view } = queryString.parse(search);
  return (
    <div className={styles.view_as}>
      <span className={styles.view_label}>View as:</span>
      <div className={styles.view_links}>
        <div className="d-flex">
          <Link to="?view=dollars" className={cn(styles.link_view, { [styles.active]: view !== 'time' })}>
            <i className={styles.icon_dollar}></i> Dollars
          </Link>
          <Link to="?view=time" className={cn(styles.link_view, { [styles.active]: view === 'time' })}>
            <i className={styles.icon_clock}></i> Time
          </Link>
        </div>
      </div>
    </div>
  );
});
