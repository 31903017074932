import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ProjectTeamEntity } from '../../store/entities/project-entities';
import { observer } from 'mobx-react-lite';
import { Menu } from '../Menu';
import './ProjectTable.scss';
import { sortFunc, teamNameFormatter, departmentFormatter, remainingFormatter } from './fotmatters';

interface Props {
  items: ProjectTeamEntity[];
}

export const ProjectTeamTable: React.FC<Props> = observer(({ items }) => {
  return (
    <div className="table-area">
      <div className="custom-table">
        <BootstrapTable keyField="id" data={items} version="4" hover bordered={false} scrollTop={'Top'}>
          <TableHeaderColumn dataField="name" sortFunc={sortFunc} dataFormat={teamNameFormatter(items)} width="15rem">
            MindTrust Team
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="department"
            dataFormat={departmentFormatter}
            dataSort
            sortFunc={sortFunc}
            width="8rem"
            tdStyle={{
              whiteSpace: 'normal',
              textOverflow: 'clip',
            }}
          >
            Department
          </TableHeaderColumn>
          <TableHeaderColumn dataField="used" dataSort sortFunc={sortFunc} width="6rem" dataAlign="right">
            Used
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField=""
            dataFormat={() => <span className="c-gray">of</span>}
            width="2.5rem"
            dataAlign="center"
          />
          <TableHeaderColumn dataField="budget" dataSort sortFunc={sortFunc} width="6rem">
            Budget
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="remaining"
            dataSort
            sortFunc={sortFunc}
            width="8rem"
            dataFormat={remainingFormatter(items)}
          >
            Remaining
          </TableHeaderColumn>
          <TableHeaderColumn dataField="costs" dataSort sortFunc={sortFunc} width="5rem">
            Costs
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField=""
            width="2.5rem"
            dataFormat={(_: any, row: ProjectTeamEntity) => <Menu {...row} />}
            tdStyle={{ overflow: 'visible' }}
          />
        </BootstrapTable>
      </div>
    </div>
  );
});
