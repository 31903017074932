import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import { Category } from '../../types';
import styles from './GroupTable.module.scss';
import { ProgressRange } from 'components/ProgressRange';

interface Props {
  name: string;
  expand: boolean;
  categories: Category[];
  timeframe: string[];
  selectedIndex?: null | number;
  onClick: (selectedIndex: null | number) => void;
}

export const GroupTable: React.FC<Props> = ({
  name,
  categories,
  timeframe,
  selectedIndex = null,
  expand = true,
  onClick,
}) => {
  const [isOpen, setIsOpen] = React.useState(expand);

  const toggle = () => setIsOpen(!isOpen);

  const onClickHandler: MouseEventHandler<any> = (e) => {
    const index = (e.target as HTMLElement).getAttribute('data-index');
    onClick(index ? Number(index) : null);
  };

  return (
    <div className={styles.group_table_holder}>
      <div className={styles.group_table_inner}>
        <table className={styles.group_table} onClick={onClickHandler}>
          <thead>
            <tr
              title="click to open/close the details"
              className={cn({ [styles.show]: isOpen })}
              onClick={expand ? toggle : undefined}
            >
              <th>
                {expand && <i className={styles.icon_open} />}
                {name}
              </th>
              {timeframe.map((_, i) => (
                <th
                  key={`${name}_${i}`}
                  className={cn({
                    [styles.selected]: selectedIndex === i,
                  })}
                />
              ))}
            </tr>
          </thead>
          {isOpen && (
            <tbody>
              {categories.map((category, index) => {
                return (
                  <tr key={`${category.name}_${index}`}>
                    <td>{category.name} <span className={styles.title}>{category.title}</span></td>
                    {timeframe.map((frame, i) => (
                      <td
                        key={`${category.name}_${i}`}
                        data-index={i}
                        className={cn({
                          [styles.selected]: selectedIndex === i,
                        })}
                      >
                        <i className={styles.icon_add} data-index={i} />
                        <span className={styles.cell_data}>{category.costs[frame]}</span>
                        <div className={styles.cell_progress}>
                          <ProgressRange value={50} valueSpent={500} valueMax={1000} />
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
