import React from 'react';
import { RegisterPhoneForm } from '../../components/Auth/RegisterPhoneForm';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../store/app-store.hook';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const RegisterPhonePage: React.FC = observer(() => {
  const { authStore } = useAppStore();

  if (!!authStore.me?.phone) return <Redirect to="/" />;

  return (
    <>
      <Helmet>
        <title>Register Phone | MindTrust</title>
      </Helmet>
      <div>
        <RegisterPhoneForm onRegister={authStore.registerPhone} error={authStore.error} />
      </div>
    </>
  );
});
