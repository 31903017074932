import React from 'react';
import { Link } from 'react-router-dom';
import { companiesRoutes } from 'routing';
import styles from './ProjectTitle.module.scss';

interface Props {
  name: string;
  agency: {
    id: string | null;
    agencyName: string | null;
  };
  client: {
    id: string | null;
    clientName: string;
  };
}

export const ProjectTitle: React.FC<Props> = ({ name, agency, client }) => {
  return (
    <div className={styles.project_headline}>
      <h1 className="page-title">{name}</h1>
      <p className={styles.project_info}>
        {agency.id && (
          <span className="text-nowrap">
            Agency:{' '}
            <Link to={companiesRoutes.AGENCY_RESELLER_VIEW(agency.id)} className="mr-5">
              {agency.agencyName}
            </Link>{' '}
          </span>
        )}
        {client.id && (
          <span className="text-nowrap">
              Client: <Link to={companiesRoutes.CLIENT_VIEW(client.id)}>{client.clientName}</Link>
          </span>
        )}
      </p>
    </div>
  );
};
