import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import styles from './ProjectMenu.module.scss';

interface Props {}

export const ProjectMenu: React.FC<Props> = () => {
  return (
    <div className={styles.project_menu}>
      <div className="text-nowrap ml-ch-6 d-inline-block valign-top">
        <Link to="#" className={styles.link_update}>
          <i className={styles.icon_update}></i>
        </Link>
        <Link to="#" className={styles.link_edit} title="Edit Project">
          <i className={styles.icon_edit}></i>
        </Link>
        <Select className="select-container w-130" classNamePrefix="select" placeholder="Actions" isMulti={false} />
      </div>
    </div>
  );
};
