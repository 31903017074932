import { observable, computed } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { ProjectOverviewQL_projectOverview_project } from 'types/gql-generated/ProjectOverviewQL';

interface ProjectInfo extends ProjectOverviewQL_projectOverview_project {}

export class ProjectInfoEntity {
  @observable private _project: ProjectOverviewQL_projectOverview_project;
  @observable private toastStore: ToastStore;
  @observable private view: string | null;

  constructor(project: ProjectInfo, toastStore: ToastStore, view: string | null) {
    this._project = project;
    this.toastStore = toastStore;
    this.view = view;
  }

  @computed
  get bonus() {
    return '';
  }

  @computed
  get teamTime() {
    return '';
  }

  @computed
  get billableExpenses() {
    return '';
  }
  @computed
  get remainBudget() {
    return '';
  }
}
