import React from 'react';
import Select, { GroupedOptionsType, OptionsType } from 'react-select';

interface FormikSpecific {
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
}

type SelectProps = Omit<Select['props'], 'options'> & { options: GroupedOptionsType<any> | OptionsType<any> };

export const FormikSelect: React.FC<SelectProps & FormikSpecific> = (props) => {
  const onChange = React.useCallback(
    (v: any) => {
      props.setFieldValue && props.setFieldValue(props.name || '', v.value, true);
    },
    [props],
  );

  const onBlur = React.useCallback(
    (v: any) => {
      props.setFieldTouched && props.setFieldTouched(props.name || '', true, true);
    },
    [props],
  );

  return (
    <Select
      {...props}
      className="select-container w-100"
      classNamePrefix="select"
      onChange={props.onChange || onChange}
      onBlur={props.onBlur || onBlur}
    />
  );
};
