import React from 'react';
import styles from './QuicklinkList.module.scss';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const options = [
  { label: 'Name', value: 'name' },
  { label: 'Date Added', value: 'dateAdded' },
  { label: 'Date Modified', value: 'dateModified' },
  { label: 'Date Created', value: 'dateCreated' },
  { label: 'Recently Used', value: 'recentlyUsed' },
]

interface Quicklink {
  id?: number;
  title: string;
  logo: string;
}

interface QuicklinkListProps {
  links: Quicklink[];
}

export const QuicklinkList: React.FC<QuicklinkListProps> = ({ links }) => {
  return (
    <div className={styles.quicklink_list}>
      <Select
        className="mr-12 select-container"
        classNamePrefix="select"
        options={options}
        placeholder="Sort by"
        isMulti={false}
      />
      <div className={`row gap-lg ${styles.quicklink_list}`}>
        {links.map((i) => (
          <div key={i.id} className="col-sm-6 col-lg-4 mb-16">
            <div className={styles.quicklink_item}>
              <Link to="#" className={styles.link_copy}>
                <i className={styles.icon_copy}></i>
              </Link>
              <Link to="#" className={styles.link_edit} title="Edit Project">
                <i className={styles.icon_edit}></i>
              </Link>
              <span className={styles.quicklink_logo}>
                <img src={i.logo} alt={i.title} />
              </span>
              <strong className={styles.quicklink_title}>{i.title}</strong>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
