import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { ProjectTitle } from '../../components/ProjectTitle';
import { ProjectMenu } from '../../components/ProjectMenu';
import { ProjectTeamTable } from '../../components/ProjectTable';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { BudgetInfo } from 'components/BudgetInfo';
import moment from 'moment';
import { TableToolbar } from 'components/TableToolbar';
import { PERIOD_SELECT_OPTIONS } from 'types/select-types';
import { ProjectNavigtaion } from './ProjectNavigation';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export const ProjectOverviewPage: React.FC = observer(() => {
  const { projectOverviewStore, timelineStore } = useAppStore();
  const { project } = projectOverviewStore;
  const { id } = useParams<{ id: string }>();

  const { goPrev, goNext, goToday, onViewChange, periodTitle, selectedView } = timelineStore;
  const toolbar = { goPrev, goNext, goToday, onViewChange, periodTitle, selectedView };

  React.useEffect(() => {
    projectOverviewStore.loadProject({
      id,
      from: moment(timelineStore.period.start).format('YYYY-MM-DD'),
      to: moment(timelineStore.period.end).format('YYYY-MM-DD'),
    });
  }, [id, projectOverviewStore, timelineStore.period]);

  return (
    <div>
      <Helmet>
        <title>{project.clientName} | Overview</title>
      </Helmet>
      <PageHeader
        title={
          <ProjectTitle
            name={project.name}
            agency={{ id: project.agencyId, agencyName: project.agencyName }}
            client={{ id: project.clientId, clientName: project.clientName }}
          />
        }
        navigation={<ProjectNavigtaion id={id} />}
        menu={<ProjectMenu />}
        toolbar={<TableToolbar {...toolbar} options={PERIOD_SELECT_OPTIONS} />}
      />
      <Row>
        <Col className="mb-24 mb-md-0" lg={{ size: 4 }}>
          <BudgetInfo getMoreHours={projectOverviewStore.getMoreHours} />
        </Col>
        <Col lg={{ size: 8 }}>
          <div className="panel h-100">
            <ProjectTeamTable items={projectOverviewStore.team} />
          </div>
        </Col>
      </Row>
    </div>
  );
});
