import { computed, observable, action } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { RouterStore } from 'mobx-react-router';
import { ProjectTeamEntity } from 'store/entities/project-entities';
import { ProjectsService } from 'store/services/projects-service';
import queryString from 'query-string';
import {
  ProjectOverviewQL_projectOverview_project,
  ProjectOverviewQL_projectOverview_team,
} from 'types/gql-generated/ProjectOverviewQL';
import { ProjectInfoEntity } from 'store/entities/project-info-entities';
import { ProjectDetailsQLVariables } from 'types/gql-generated/ProjectDetailsQL';

export class ProjectDetailsStore {
  private projectsService: ProjectsService;
  private toastStore: ToastStore;
  @observable private _team: ProjectOverviewQL_projectOverview_team[] = [];
  @observable project: ProjectOverviewQL_projectOverview_project = {
    id: '',
    name: '',
    clientId: '',
    clientName: '',
    agencyId: null,
    agencyName: null,
  };
  @observable private routerStore: RouterStore;

  constructor(projectsService: ProjectsService, routerStore: RouterStore, toastStore: ToastStore) {
    this.projectsService = projectsService;
    this.routerStore = routerStore;
    this.toastStore = toastStore;
  }

  get projectId() {
    return 1;
  }

  @action
  loadProjectDetails = async (input: ProjectDetailsQLVariables['input']) => {};

  @computed
  get team(): ProjectTeamEntity[] {
    const { search } = this.routerStore.location;
    const { view } = queryString.parse(search);
    return this._team.map((m) => new ProjectTeamEntity(m, this.toastStore, view as string));
  }

  @computed
  get projectInfo(): ProjectInfoEntity {
    const { search } = this.routerStore.location;
    const { view } = queryString.parse(search);
    return new ProjectInfoEntity(this.project, this.toastStore, view as string);
  }

  @action
  getMoreHours = () => {
    this.toastStore.showSuccessMessage('More hours reguested');
  };
}
