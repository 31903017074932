import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject2ResourceBased } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest2ResourcesPage = observer(() => {
  const { requestProjectStore } = useAppStore();
  const { projectRequest, skillsSelectOptions } = requestProjectStore;

  React.useEffect(() => {
    requestProjectStore.preloadProjectSkills();
  }, [requestProjectStore]);

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject2ResourceBased
        skillsOptions={skillsSelectOptions}
        loadOptions={requestProjectStore.loadProjectSkills}
        initial={projectRequest}
        onSend={requestProjectStore.secondStepResourseBased}
        goBack={requestProjectStore.goBack}
        promoReview={requestProjectStore.getPromoReview()}
      />
    </>
  );
});
