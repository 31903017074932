import gql from 'graphql-tag';

const USER_STATUS = gql`
  fragment UserStatusFG on UserStatus {
    roles
    firstName
    lastName
    email
    emailVerified
    phone
    phoneVerified
    twoFactorEnabled
    pendingInvites
  }
`;

const USER_STATUS_AUTH = gql`
  fragment UserStatusAuthFG on UserStatusAuthentication {
    roles
    firstName
    lastName
    email
    emailVerified
    phone
    phoneVerified
    twoFactorEnabled
    pendingInvites
    authentication {
      token
      refreshToken
      roles
    }
  }
`;

export const ME = gql`
  query MeQL {
    me {
      ...UserStatusFG
    }
  }
  ${USER_STATUS}
`;

export const AUTHENTICATE = gql`
  mutation AuthenticateQL($input: AuthenticationInput!) {
    authenticate(input: $input) {
      token
      refreshToken
      roles
    }
  }
`;

export const REGISTER = gql`
  mutation RegisterQL($input: RegisterInput!) {
    register(input: $input) {
      ...UserStatusFG
    }
  }
  ${USER_STATUS}
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmailQL {
    verifyEmail {
      ...UserStatusAuthFG
    }
  }
  ${USER_STATUS_AUTH}
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPasswordQL($input: EmailInput!) {
    requestResetPasswordEmail(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordQL($input: NewPasswordInput!) {
    resetPassword(input: $input) {
      ...UserStatusAuthFG
    }
  }
  ${USER_STATUS_AUTH}
`;

export const VERIFY_INVITE = gql`
  mutation VerifyInviteQL($input: VerifyInviteInput!) {
    verifyInvite(input: $input) {
      ...UserStatusAuthFG
    }
  }
  ${USER_STATUS_AUTH}
`;

export const REQUEST_CODE = gql`
  mutation RequestCodeQL {
    requestCode
  }
`;

export const REGISTER_PHONE = gql`
  mutation RegisterPhoneQL($input: PhoneInput!) {
    registerPhone(input: $input) {
      ...UserStatusAuthFG
    }
  }
  ${USER_STATUS_AUTH}
`;

export const VERIFY_PHONE = gql`
  mutation VerifyPhoneQL($input: CodeInput!) {
    verifyPhone(input: $input) {
      ...UserStatusAuthFG
    }
  }
  ${USER_STATUS_AUTH}
`;
