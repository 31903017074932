import React from 'react';
import styles from './AddCreditCategoryPopup.module.scss';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, FormFeedback } from 'reactstrap';
import { useFormik } from 'formik';
import { CreditCategoryCreate } from '../../types/project-types';
import Select from 'react-select';

interface FormValues {
  creditCategory: string;
}

interface QuicklinkFormProps {
  error?: string;
  onCancel?: () => void;
  onSend: (params: CreditCategoryCreate) => void;
}

const initialValues = {
  creditCategory: '',
};

const options = [
  { label: 'Media Tax Credit', value: '' },
  { label: 'Loan Forgiveness Credit', value: '' },
  { label: 'Birthday', value: '' },
  { label: 'Anniversary', value: '' },
  { label: 'Loyal Customer', value: '' },
  { label: 'Credit Category ', value: '' },
  { label: 'Other', value: '' },
  { label: 'Media Tax Credit', value: '' },
  { label: 'Loan Forgiveness Credit', value: '' },
  { label: 'Birthday', value: '' },
  { label: 'Anniversary', value: '' },
  { label: 'Loyal Customer', value: '' },
  { label: 'Credit Category ', value: '' },
  { label: 'Other', value: '' },
  { label: 'Media Tax Credit', value: '' },
  { label: 'Loan Forgiveness Credit', value: '' },
  { label: 'Birthday', value: '' },
  { label: 'Anniversary', value: '' },
  { label: 'Loyal Customer', value: '' },
  { label: 'Credit Category ', value: '' },
  { label: 'Other', value: '' },
];

export const AddCreditCategoryPopup: React.FC<QuicklinkFormProps> = ({ onCancel, onSend, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onSend,
    isInitialValid: false,
    // @tsignore
    // validate: validate<FormValues>(validationSchema),
  });

  const { errors, handleSubmit, isValid } = formik;

  return (
    <div className={`${styles.popup_holder} d-flex align-items-center justify-content-center`}>
      <Card className={styles.popup}>
        <CardHeader>
          <h3>Add Credit Category</h3>
          <p>Add another category to group credits.</p>
        </CardHeader>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <CardBody className="mh-371">
            <FormGroup>
              <label htmlFor="creditCategory">Quicklink Title</label>
              <Select
                id="creditCategory"
                className="w-100 select-container"
                classNamePrefix="select"
                options={options}
                placeholder="Select"
                isMulti={true}
                closeMenuOnSelect={false}
              />
              <FormFeedback>{errors.creditCategory}</FormFeedback>
            </FormGroup>
          </CardBody>
          <CardFooter className="text-right">
            <Button type="button" color="outline-secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="button" color="primary" className="ml-10" disabled={!isValid} onClick={handleSubmit}>
              Create Quicklink
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </div>
  );
};
