import { Role } from 'types/gql-generated';

export const myExpenses = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
  Role.MT_OUTSIDE_REP,
];
export const teamExpenses = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
];
