import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { globalSettingsRoutes } from '../../routing/routes';
import { UserGroupsPage } from './UserGroupsPage';
import { DepartmentsPage } from './DepartmentsPage';
import { ProjectRolesPage } from './ProjectRolesPage';
import { ExpenseCategoriesPage } from './ExpenseCategoriesPage';

export const GlobalSettingsRoutes: React.FC = observer(() => {
  const { globalSettings } = useAppStore().permissionStore;
  return (
    <Switch>
      {globalSettings.userGroups && <Route exact path={globalSettingsRoutes.USER_GROUPS} component={UserGroupsPage} />}
      {globalSettings.departments && (
        <Route exact path={globalSettingsRoutes.DEPARTMENTS} component={DepartmentsPage} />
      )}
      {globalSettings.projectRoles && (
        <Route exact path={globalSettingsRoutes.PROJECT_ROLES} component={ProjectRolesPage} />
      )}
      {globalSettings.expenseCategories && (
        <Route exact path={globalSettingsRoutes.EXPENSE_CATEGORIES} component={ExpenseCategoriesPage} />
      )}
    </Switch>
  );
});
