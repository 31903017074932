import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { InvintationForm } from 'components/InvintationForm';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

export const DashboardPage: React.FC = observer(() => {
  const { authStore, referralsStore } = useAppStore();
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <h1 className="mb-5 mt-5">DashboardPage</h1>
      <Row>
        <Col>
          <InvintationForm onInvite={referralsStore.inviteUser} />
        </Col>
        <Col>
          <h2>Your info</h2>
          <pre>{JSON.stringify(authStore.me, null, 2)}</pre>
        </Col>
      </Row>
    </>
  );
});
