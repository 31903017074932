import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { Loader } from 'components/Loader';
import * as queryString from 'query-string';
import { CheckEmailIcon } from '../../components/SvgIcons/CheckEmail';
import { Helmet } from 'react-helmet';

export const VerifyEmailPage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const { access_token } = queryString.parse(location.search);
    (async function () {
      await authStore.verifyEmail(access_token as string);
      setIsLoading(false);
    })();
  }, [authStore, location.search]);

  return (
    <>
      <Helmet>
        <title>Verify Email | MindTrust</title>
      </Helmet>
      <Card className="d-flex flex-row align-items-center mh-315 w-23">
        <CardBody>
          {isLoading ? (
            <div className="loader-fixed">
              <Loader />
            </div>
          ) : (
            <div className="text-center">
              <div className="icon mb-med">
                <CheckEmailIcon className="icon" />
              </div>
              <div className="card-headline text-center">
                <h1>Your email has been successfuly verified!</h1>
              </div>
              <Link to="/">Go to app</Link>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
});
