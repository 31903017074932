import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../store/app-store.hook';
import { ForgotPasswordForm } from '../../components/Auth/ForgotPasswordForm';
import { Redirect } from 'react-router-dom';
import { authRoutes } from 'routing';
import { Helmet } from 'react-helmet';

export const ForgotPasswordPage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const [isSent, setIsSent] = React.useState(false);

  const onForgotPassword = React.useCallback(
    async ({ email }) => {
      await authStore.forgotPassword(email);
      setIsSent(true);
    },
    [authStore],
  );

  if (isSent) return <Redirect to={authRoutes.CHECK_EMAIL} />;
  return (
    <>
      <Helmet>
        <title>Forgot Password | MindTrust</title>
      </Helmet>
      <div>
        {!isSent && <ForgotPasswordForm onSend={onForgotPassword} error={authStore.error} />}
      </div>
    </>
  );
});
