import React from 'react';
import * as history from 'history';
import { Router } from 'react-router-dom';
import { AppRouter } from './routing';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { AppStore } from './store';
import { AppStoreProvider } from './store/app-store.hook';
import { ToastProvider } from 'react-toast-notifications';
import { ToastNotifcations } from 'components/ToastNotifications';

const browserHistory = history.createBrowserHistory();
const routerStore = new RouterStore();
const syncHistory = syncHistoryWithStore(browserHistory, routerStore);
const appStore = new AppStore(routerStore);
const createAppStore = () => appStore;

export const App = () => {
  const [isInitialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    (async function initializeApp() {
      await appStore.authStore.initialize();
      setInitialized(true);
    })();
  }, []);

  return isInitialized ? (
    <Router history={syncHistory}>
      <AppStoreProvider createAppStore={createAppStore}>
        <ToastProvider placement="bottom-right" autoDismissTimeout={2000}>
          <ToastNotifcations />
          <AppRouter />
        </ToastProvider>
      </AppStoreProvider>
    </Router>
  ) : null;
};
