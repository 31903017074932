import React, { ReactElement } from 'react';

interface Props {
  title?: ReactElement;
  navigation?: ReactElement;
  menu?: ReactElement;
  toolbar?: ReactElement;
}

export const PageHeader: React.FC<Props> = ({ title, navigation, menu, toolbar }) => {
  return (
    <div className="top-panel mb-14">
      <div className="top-bar d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-top">
        <div className="d-xl-inline-block">{title}</div>
        <div className="submenu-holder text-nowrap">{navigation}</div>
        <div className="text-right">{menu}</div>
      </div>
      {toolbar}
    </div>
  );
};
