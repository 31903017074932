import React from 'react';

interface Props {
  className?: string;
}

export const CheckEmailIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.046 42C5.863 41.975.025 36.136 0 28.954.025 21.795 5.836 16 13 16c7.18 0 13 5.82 13 13 0 7.164-5.796 12.975-12.954 13zM2.461 28.908c0 5.862 4.769 10.631 10.631 10.631s10.631-4.77 10.631-10.631c0-5.862-4.769-10.631-10.63-10.631-5.863 0-10.632 4.769-10.632 10.63z"
        fill="#009BFF"
      />
      <circle cx="13" cy="29" r="11" fill="#E2F3FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.707 25.293a1 1 0 010 1.414L11 33.414l-3.707-3.707a1 1 0 111.414-1.414L11 30.586l5.293-5.293a1 1 0 011.414 0z"
        fill="#009BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.137 0h31.631c.68 0 1.23.55 1.23 1.23v23.724c0 .68-.55 1.23-1.23 1.23H27.735a14.889 14.889 0 00-.69-2.46h12.492V3.78l-13.85 10.3a1.228 1.228 0 01-1.469 0l-13.85-10.3v10.45c-.848.15-1.67.37-2.462.656V1.23c0-.68.551-1.23 1.23-1.23zm15.815 11.559L37.187 2.46h-24.47l12.235 9.098z"
        fill="#000"
      />
    </svg>
  );
};
