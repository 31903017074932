import { GQLConnector } from 'store/qgl-connector';
import * as projectsQueiries from '../../queries/projects-queries';
import { ProjectsQLVariables, ProjectsQL } from 'types/gql-generated';
import { RequestProjectQLVariables, RequestProjectQL } from 'types/gql-generated/RequestProjectQL';
import {
  SearchProjectSolutionsQL,
  SearchProjectSolutionsQLVariables,
} from 'types/gql-generated/SearchProjectSolutionsQL';
import { SearchProjectSkillsQL, SearchProjectSkillsQLVariables } from 'types/gql-generated/SearchProjectSkillsQL';
import { ProjectOverviewQLVariables, ProjectOverviewQL } from 'types/gql-generated/ProjectOverviewQL';
import { ProjectDetailsQLVariables, ProjectDetailsQL } from 'types/gql-generated/ProjectDetailsQL';

export class ProjectsService {
  private qglConnector: GQLConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }

  loadProjects = async (input: ProjectsQLVariables['input']): Promise<ProjectsQL['projects']> =>
    (
      await this.qglConnector.mutate<ProjectsQL>({
        mutation: projectsQueiries.PROJECTS,
        variables: { input },
      })
    ).projects;

  loadProjectOverview = async (
    input: ProjectOverviewQLVariables['input'],
  ): Promise<ProjectOverviewQL['projectOverview']> =>
    (
      await this.qglConnector.query<ProjectOverviewQL, ProjectOverviewQLVariables>({
        query: projectsQueiries.PROJECT_OVERVIEW,
        variables: { input },
      })
    ).projectOverview;

  loadProjectDetails = async (
    input: ProjectDetailsQLVariables['input'],
  ): Promise<ProjectDetailsQL['projectDetails']> =>
    (
      await this.qglConnector.query<ProjectDetailsQL, ProjectDetailsQLVariables>({
        query: projectsQueiries.PROJECT_DETAILS,
        variables: { input },
      })
    ).projectDetails;

  requestProject = async (input: RequestProjectQLVariables['input']): Promise<RequestProjectQL['addProjectRequest']> =>
    (
      await this.qglConnector.mutate<RequestProjectQL>({
        mutation: projectsQueiries.REQUES_PROJECT,
        variables: { input },
      })
    ).addProjectRequest;

  loadProjectSolutions = async (
    input: SearchProjectSolutionsQLVariables['input'],
  ): Promise<SearchProjectSolutionsQL['searchProjectSolutions']> =>
    (
      await this.qglConnector.query<SearchProjectSolutionsQL, SearchProjectSolutionsQLVariables>({
        query: projectsQueiries.PROJECT_SOLUTIONS,
        variables: { input },
      })
    ).searchProjectSolutions;

  loadProjectSkills = async (
    input: SearchProjectSkillsQLVariables['input'],
  ): Promise<SearchProjectSkillsQL['searchSkills']> =>
    (
      await this.qglConnector.query<SearchProjectSkillsQL, SearchProjectSkillsQLVariables>({
        query: projectsQueiries.PROJECT_SKILLS,
        variables: { input },
      })
    ).searchSkills;
}
