import { GQLConnector } from 'store/qgl-connector';
import * as AuthQueriers from 'queries/auth-queries';
import {
  RegisterQLVariables,
  RegisterPhoneQLVariables,
  MeQL,
  RegisterQL,
  RegisterPhoneQL,
  VerifyEmailQL,
  AuthenticateQLVariables,
  AuthenticateQL,
} from 'types/gql-generated';
import { ResetPasswordQLVariables } from 'types/gql-generated/ResetPasswordQL';
import { RequestResetPasswordQLVariables } from 'types/gql-generated/RequestResetPasswordQL';
import { VerifyInviteQL, VerifyInviteQLVariables } from 'types/gql-generated/VerifyInviteQL';
import { RequestCodeQL } from 'types/gql-generated/RequestCodeQL';
import { VerifyPhoneQLVariables, VerifyPhoneQL } from 'types/gql-generated/VerifyPhoneQL';

export class AuthService {
  private qglConnector: GQLConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }

  me = async (): Promise<MeQL> =>
    this.qglConnector.query<any, MeQL>({
      query: AuthQueriers.ME,
    });

  authenticate = async (input: AuthenticateQLVariables['input']): Promise<AuthenticateQL['authenticate']> =>
    (
      await this.qglConnector.mutate<AuthenticateQL>({
        mutation: AuthQueriers.AUTHENTICATE,
        variables: {
          input,
        },
      })
    ).authenticate;

  register = async (input: RegisterQLVariables['input']): Promise<RegisterQL['register']> =>
    (
      await this.qglConnector.mutate<RegisterQL>({
        mutation: AuthQueriers.REGISTER,
        variables: {
          input,
        },
      })
    ).register;

  registerPhone = async (input: RegisterPhoneQLVariables['input']): Promise<RegisterPhoneQL['registerPhone']> =>
    (
      await this.qglConnector.mutate<RegisterPhoneQL>({
        mutation: AuthQueriers.REGISTER_PHONE,
        variables: { input },
      })
    ).registerPhone;

  requestCode = async (): Promise<RequestCodeQL['requestCode']> =>
    (
      await this.qglConnector.mutate<RequestCodeQL>({
        mutation: AuthQueriers.REQUEST_CODE,
      })
    ).requestCode;

  verifyPhone = async (input: VerifyPhoneQLVariables['input']): Promise<VerifyPhoneQL['verifyPhone']> =>
    (
      await this.qglConnector.mutate<VerifyPhoneQL>({
        mutation: AuthQueriers.VERIFY_PHONE,
        variables: { input },
      })
    ).verifyPhone;

  verifyEmail = async (): Promise<VerifyEmailQL['verifyEmail']> =>
    (
      await this.qglConnector.mutate<VerifyEmailQL>({
        mutation: AuthQueriers.VERIFY_EMAIL,
      })
    ).verifyEmail;

  verifyInvite = async (input: VerifyInviteQLVariables['input']): Promise<VerifyInviteQL['verifyInvite']> =>
    (
      await this.qglConnector.mutate<VerifyInviteQL>({
        mutation: AuthQueriers.VERIFY_INVITE,
        variables: { input },
      })
    ).verifyInvite;

  requestResetPassword = async (input: RequestResetPasswordQLVariables['input']): Promise<void> =>
    await this.qglConnector.mutate<void>({
      mutation: AuthQueriers.REQUEST_RESET_PASSWORD,
      variables: { input },
    });

  resetPassword = async (input: ResetPasswordQLVariables['input']): Promise<void> =>
    await this.qglConnector.mutate<void>({
      mutation: AuthQueriers.RESET_PASSWORD,
      variables: { input },
    });
}
