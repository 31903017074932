import * as Moment from 'moment';
import * as MomentRange from 'moment-range';
import queryString from 'query-string';
import _ from 'lodash';
import { DateGrouping } from 'types/gql-generated';

const moment = MomentRange.extendMoment(Moment);

const sameMonth = (a: any, b: any, other: any) => {
  if (a.month() !== b.month()) {
    return other;
  }
  return a.date();
};

const weeks = (m: any) => {
  let lastOfMonth = m.clone().endOf('month'),
    lastOfMonthDate = lastOfMonth.date(),
    firstOfMonth = m.clone().startOf('month'),
    currentWeek = firstOfMonth.clone().day(0),
    output = [],
    startOfWeek,
    endOfWeek;

  while (currentWeek < lastOfMonth) {
    startOfWeek = sameMonth(currentWeek.clone().day(1), firstOfMonth, 1);
    endOfWeek = sameMonth(currentWeek.clone().day(7), firstOfMonth, lastOfMonthDate);

    output.push(startOfWeek + '-' + endOfWeek);
    currentWeek.add('d', 7);
  }

  return output;
};

export interface Range {
  start: Date;
  end: Date;
}

export interface Timeframe {
  month: string;
  weeks: string[];
  days: string[];
}

export const getViewFromUrl = (str: string): DateGrouping => {
  const view = queryString.parse(str).view as string;
  if (view === DateGrouping.MONTH) return DateGrouping.MONTH;
  if (view === DateGrouping.WORK_WEEK) return DateGrouping.WORK_WEEK;
  if (view === DateGrouping.DAY) return DateGrouping.DAY;
  return DateGrouping.MONTH;
};

export const curentYearRange = { start: moment().startOf('year').toDate(), end: moment().endOf('year').toDate() };
export const curentMonthRange = { start: moment().startOf('month').toDate(), end: moment().endOf('month').toDate() };
export const curentWeekRange = { start: moment().startOf('week').toDate(), end: moment().endOf('week').toDate() };
export const todayRange = { start: new Date(), end: new Date() };

export const getRangeFromUrl = (str: string, fallback: Range): Range => {
  const { start, end } = queryString.parse(str);
  if (!start || !end) return fallback;
  return {
    start: new Date(start as string),
    end: new Date(end as string),
  };
};

export const getUrlParamsFromRange = ({ start, end }: Range): string => {
  return `start=${moment(start).format('YYYY-MM-DD')}&end=${moment(end).format('YYYY-MM-DD')}`;
};

export const getTimeframe = (dateRange: Range): Timeframe[] => {
  const range = moment.range(dateRange.start, dateRange.end);

  return Array.from(range.by('month')).map((month) => {
    const monthStart = month.startOf('month').toDate();
    const monthEnd = month.endOf('month').toDate();
    const monthRange = moment.range(monthStart, monthEnd);
    return {
      month: month.format('MMM YYYY'),
      weeks: weeks(month),
      days: Array.from(monthRange.by('day')).map((day) => day.format('ddd')),
    };
  });
};

export const getWeekTimeframe = (): any => {
  const range = moment.range(new Date(2020, 0, 1), new Date(2020, 1, 5));
  return Array.from(range.by('month')).flatMap((month) => {
    return {
      month: month.format('MMM YYYY'),
      weeks: weeks(month),
    };
  });
};

export const getDayTimeframe = (dateRange: Range) => {
  const range = moment.range(dateRange.start, dateRange.end);
  const daysWithWeeks = Array.from(range.by('day')).map((day) => ({
    week: `${day.format('MMM YYYY')} Week ${Math.ceil(day.date() / 7)}`,
    day: day.format('DD ddd'),
  }));

  const groupedByWeek: {
    [key: string]: { week: string; day: string }[];
  } = _.groupBy(daysWithWeeks, ({ week }) => week);

  return Object.keys(groupedByWeek).map((key) => {
    return {
      week: `${key}`,
      days: groupedByWeek[key].map(({ day }) => day),
    };
  });
};

export const getMonthTimeframe = (dateRange: Range): string[] => {
  const range = moment.range(dateRange.start, dateRange.end);
  return Array.from(range.by('month')).map((month) => month.format('MMM YYYY'));
};

export type Duration = 'month' | 'week' | 'day';

export const getNextFrame = (range: Range, shift: number, duration: Duration): Range => {
  return {
    start: moment(range.start).add(duration, shift).toDate(),
    end: moment(range.end).add(duration, shift).toDate(),
  };
};

export const getCurrentFrame = (shift: number, duration: Duration): Range => {
  const start = moment().startOf(duration).toDate();
  return {
    start,
    end: moment(start)
      .add(duration, shift - 1)
      .toDate(),
  };
};

export const getPrevFrame = (range: Range, shift: number, duration: Duration): Range => {
  return {
    start: moment(range.start).add(duration, -shift).toDate(),
    end: moment(range.end).add(duration, -shift).toDate(),
  };
};

export const getFrame = (range: Range, shift: number, duration: Duration): Range => {
  return {
    start: range.start,
    end: moment(range.start)
      .add(duration, shift - 1)
      .toDate(),
  };
};
