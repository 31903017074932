import React from 'react';
import { SubMenu } from '../../components/SubMenu';
import { PageTitle } from '../../components/PageTitle';
import { ProjectMenu } from '../../components/ProjectMenu';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { DateTable } from 'components/DateTable';
import { Helmet } from 'react-helmet';

export const AgencyResellerDetailsPage: React.FC = observer(() => {
  const { projectOverviewStore } = useAppStore();
  return (
    <div>
      <Helmet>
        <title>MindTrust | Details</title>
      </Helmet>
      <div className="top-panel">
        <div className="top-bar d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-top">
          <div className="d-xl-inline-block">
            <PageTitle name={'MindTrust'} info={'31 Active Clients'} extraInfo={'87 Active Projects'} />
          </div>
          <div className="submenu-holder text-nowrap">
            <SubMenu
              items={[
                {
                  label: 'Overview',
                  href: `/agency-resellers/${projectOverviewStore.projectId}`,
                },
                {
                  label: 'Details',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/details`,
                },
                {
                  label: 'Reports',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/reports`,
                },
                {
                  label: 'Invoices',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/invoices`,
                },
                {
                  label: 'Quicklinks',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/quicklinks`,
                },
              ]}
            />
          </div>
          <div className="text-right">
            <ProjectMenu />
          </div>
        </div>
      </div>
      <DateTable />
    </div>
  );
});
