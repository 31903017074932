import React from 'react';

interface Props {
  className?: string;
}

export const SuccessIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      width="62"
      height="83"
      viewBox="0 0 62 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="29.5652" cy="37.4491" r="24.0461" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1612 0.296244L60.736 15.8825C61.5153 16.3074 62 17.1241 62 18.0114V28.3033C62 39.0606 59.264 49.7307 54.0877 59.1602C48.913 68.5872 41.3825 76.6223 32.3109 82.397C31.9329 82.6405 31.4828 82.7817 30.9998 82.7817C30.5169 82.7817 30.0669 82.6405 29.6891 82.3972C20.6174 76.6225 13.087 68.5873 7.91229 59.1604C2.736 49.7307 0 39.0606 0 28.3035V18.0115C0 17.1241 0.484726 16.3074 1.26388 15.8825L29.8386 0.296244C30.5623 -0.098748 31.4374 -0.098748 32.1612 0.296244ZM12.9883 38.7932C12.9883 48.7246 21.0681 56.8045 30.9998 56.8045C40.9316 56.8045 49.0115 48.7247 49.0113 38.7932C49.0113 28.8616 40.9314 20.7817 30.9998 20.7817C21.0683 20.7817 12.9883 28.8615 12.9883 38.7932ZM31 43.816C31.6206 43.816 32.2414 43.5793 32.715 43.1057L42.8162 33.0046C43.676 34.7525 44.161 36.717 44.161 38.7932C44.161 46.05 38.257 51.954 31 51.954C23.743 51.954 17.839 46.0502 17.839 38.7932C17.839 31.5362 23.7429 25.6322 31 25.6322C34.4174 25.6322 37.534 26.9421 39.8761 29.0852L31 37.9611L27.5195 34.4803C26.5723 33.5333 25.0367 33.5333 24.0897 34.4803C23.1425 35.4274 23.1425 36.9631 24.0897 37.9101L29.2852 43.1056C29.7586 43.5793 30.3793 43.816 31 43.816Z"
        fill="#0059FF"
      />
    </svg>
  );
};
