import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject6 } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest6Page = observer(() => {
  const { requestProjectStore } = useAppStore();
  const { projectRequest, submit } = requestProjectStore;

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject6 {...projectRequest} onResend={submit} promoReview={requestProjectStore.getPromoReview()} />
    </>
  );
});
