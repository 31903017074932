import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { companiesRoutes } from 'routing';
import { CompaniesPage } from './CompaniesPage';
import { AgencyResellersPage } from './AgencyResellersPage';
import { ClientsPage } from './ClientsPage';
import { AgencyTalentPartnersPage } from './AgencyTalentPartners';
import { SalesRepCompaniesPage } from './SalesRepCompaniesPage';
import { ClientOverviewPage } from './ClientOverviewPage';
import { ClientDetailsPage } from './ClientDetailsPage';
import { AgencyResellerOverviewPage } from './AgencyResellerOverviewPage';
import { AgencyResellerDetailsPage } from './AgencyResellerDetailsPage';

export const CompaniesRoutes: React.FC = observer(() => {
  const { companies } = useAppStore().permissionStore;
  return (
    <Switch>
      {companies.companies && <Route exact path={companiesRoutes.COMPANIES} component={CompaniesPage} />}
      {companies.clients && <Route exact path={companiesRoutes.CLIENTS} component={ClientsPage} />}
      {companies.clients && <Route exact path={companiesRoutes.CLIENT_VIEW()} component={ClientOverviewPage} />}
      {companies.clients && <Route exact path={companiesRoutes.CLIENT_DETAIL()} component={ClientDetailsPage} />}
      {companies.agencyResellers && (
        <Route exact path={companiesRoutes.AGENCY_RESELLERS} component={AgencyResellersPage} />
      )}
      {companies.agencyResellers && <Route exact path={companiesRoutes.AGENCY_RESELLER_VIEW()} component={AgencyResellerOverviewPage} />}
      {companies.agencyResellers && <Route exact path={companiesRoutes.AGENCY_RESELLER_DETAIL()} component={AgencyResellerDetailsPage} />}
      {companies.agencyTalentPartners && (
        <Route exact path={companiesRoutes.AGENCY_TALENT_PARTNERS} component={AgencyTalentPartnersPage} />
      )}
      {companies.salesRepCompanies && (
        <Route exact path={companiesRoutes.SALES_REP_COMPANIES} component={SalesRepCompaniesPage} />
      )}
    </Switch>
  );
});
