import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { SubMenu } from '../../components/SubMenu';
import { PageTitle } from '../../components/PageTitle';
import { ProjectMenu } from '../../components/ProjectMenu';
import { Pie } from '../../components/Pie';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';

export const AgencyResellerOverviewPage: React.FC = observer(() => {
  const { projectOverviewStore } = useAppStore();
  return (
    <div>
      <Helmet>
        <title>MindTrust | Overview</title>
      </Helmet>
      <div className="top-panel mb-14">
        <div className="top-bar d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-top">
          <div className="d-xl-inline-block">
            <PageTitle name={'MindTrust'} info={'31 Active Clients'} extraInfo={'87 Active Projects'} />
          </div>
          <div className="submenu-holder text-nowrap">
            <SubMenu
              items={[
                {
                  label: 'Overview',
                  href: `/agency-resellers/${projectOverviewStore.projectId}`,
                },
                {
                  label: 'Details',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/details`,
                },
                {
                  label: 'Reports',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/reports`,
                },
                {
                  label: 'Invoices',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/invoices`,
                },
                {
                  label: 'Quicklinks',
                  href: `/agency-resellers/${projectOverviewStore.projectId}/quicklinks`,
                },
              ]}
            />
          </div>
          <div className="text-right">
            <ProjectMenu />
          </div>
        </div>
        {/* <TableToolbar {...{ ...navigation, periodRange, options, selectedView: view, onViewChange }} /> */}
      </div>
      <Row>
        <Col className="mb-24 mb-md-0" lg={{ size: 4 }}>
          <div className="panel panel--paddings h-100">
            <Pie value={15} title={'days left in cycle'} />
            <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
              <dt style={{ display: '1px solid #d4dee5' }}>Budget</dt>
              <dd>$44,000</dd>
            </dl>
            <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
              <dt>Bonus</dt>
              <dd>$10,000</dd>
            </dl>
            <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
              <dt>
                Team Time{' '}
                <span
                  className="note-tooltip"
                  data-place="right"
                  data-multiline="true"
                  data-text-color="#151515"
                  data-border="true"
                  data-border-color="#D5DFE6"
                  data-tip="A team member has an unsubmitted timesheet for the<br> active period and that the numbers may change."
                >
                  !
                </span>
                <ReactTooltip type="light" className="tooltip" />
              </dt>
              <dd>$31,128</dd>
            </dl>
            <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
              <dt>Billable Expenses</dt>
              <dd>$150</dd>
            </dl>
            <dl
              className="d-flex justify-content-between"
              style={{ borderBottom: '1px solid #d4dee5', color: '#2e353a' }}
            >
              <dt>Remaining Budget</dt>
              <dd>$1,477</dd>
            </dl>
            <div className="get_more mtb-25">
              <Button className="mb-7" color="tertiary w-100" onClick={projectOverviewStore.getMoreHours}>
                Get more hours <i className="icon-arrow-right"></i>
              </Button>
              <p className="fs-24">
                Note: Access to manage billing account is role specific. Please contact your Compny Administrator or
                Billing Contact.
              </p>
              <div className="d-flex justify-content-between">
                <NavLink to="#" className="simple-link">
                  Explore Unlimited
                </NavLink>
                <NavLink to="#" className="simple-link">
                  Manage Alert Settings
                </NavLink>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={{ size: 8 }}>
          <div className="panel h-100">{/* <CompaniesAgencyTable companies={companies} /> */}</div>
        </Col>
      </Row>
    </div>
  );
});
