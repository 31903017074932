import { Role } from 'types/gql-generated';

export const termsOfService = [];
export const FAQSupport = [];
export const teamHandbook = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
  Role.MT_OUTSIDE_REP,
];
export const talentPartnerHandbook = [
  Role.AGENCY_TALENT_PARTNER_OWNER,
  Role.AGENCY_TALENT_PARTNER_ADMIN,
  Role.AGENCY_TALENT_PARTNER_BILLING_CONTACT,
  Role.AGENCY_TALENT_PARTNER_USER,
];
export const brandGuidelines = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
  Role.MT_OUTSIDE_REP,
  Role.SALES_REP_COMPANY_OWNER,
  Role.SALES_REP_COMPANY_ADMIN,
  Role.SALES_REP_COMPANY_BILLING_CONTACT,
];
export const salesRepCompanyHandbook = [];
export const salesCollateral = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
  Role.MT_OUTSIDE_REP,
  Role.SALES_REP_COMPANY_OWNER,
  Role.SALES_REP_COMPANY_ADMIN,
  Role.SALES_REP_COMPANY_BILLING_CONTACT,
  Role.SALES_REP_COMPANY_REP,
];
