import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';

import styles from './Footer.module.scss';

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <span className={styles.copy}>
        &copy; 2020{' '}
        <Link to="https://mindtrust.com" target="_blank">
          MNDTRST
        </Link>
        , Inc.
      </span>
      <Nav className={styles.footer_nav}>
        <NavItem>
          <NavLink to="/terms" className="nav-link">
            Terms of Service
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/privacy-policy" className="nav-link">
            Privacy Policy
          </NavLink>
        </NavItem>
      </Nav>
      {/* <span className="ml-auto">Powered by best team ever</span> */}
    </div>
  );
};
