import React from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { requestProjectRoutes } from '../../routing';
import { ProjectRequest1Page } from './ProjectRequest1Page';
import { useAppStore } from 'store/app-store.hook';
import { ProjectRequest2Page } from './ProjectRequest2Page';
import { ProjectRequest3Page } from './ProjectRequest3Page';
import { ProjectRequest4Page } from './ProjectRequest4Page';
import { ProjectRequest5Page } from './ProjectRequest5Page';
import { ProjectRequest6Page } from './ProjectRequest6Page';
import { ProjectRequest2ResourcesPage } from './ProjectRequest2ResourcesPage';

export const ProjectRequestRoutes: React.FC = observer(() => {
  const { step } = useParams<{ step: string }>();
  const { requestProjectStore } = useAppStore();
  const { currentStep } = requestProjectStore;

  React.useEffect(() => {
    requestProjectStore.preloadSolutions();
    requestProjectStore.preloadProjectSkills();
  }, [requestProjectStore]);
  const { search } = useLocation();
  if (+step > currentStep) return <Redirect to={`${requestProjectRoutes.request(currentStep)}${search}`} />;
  return (
    <Switch>
      <Route
        exact
        path={requestProjectRoutes.main}
        component={() => {
          const { search } = useLocation();
          return <Redirect to={`${requestProjectRoutes.request(1)}${search}`} />;
        }}
      />
      <Route exact path={requestProjectRoutes.request(1)} component={ProjectRequest1Page} />
      <Route exact path={requestProjectRoutes.request(2)} component={ProjectRequest2Page} />
      <Route exact path={`${requestProjectRoutes.request(2)}/resources`} component={ProjectRequest2ResourcesPage} />
      <Route exact path={requestProjectRoutes.request(3)} component={ProjectRequest3Page} />
      <Route exact path={requestProjectRoutes.request(4)} component={ProjectRequest4Page} />
      <Route exact path={requestProjectRoutes.request(5)} component={ProjectRequest5Page} />
      <Route exact path={requestProjectRoutes.request(6)} component={ProjectRequest6Page} />
    </Switch>
  );
});
