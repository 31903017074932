import { ProjectsView } from 'types/project-types';

const numberWithCommas = (x: number | string): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const camelToNormal = (str: string): string =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

export const camelToKebab = (str: string): string => str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

export const pretify = (v: string | number, view: string | null): string => {
  return view !== ProjectsView.TIME ? `$${numberWithCommas(v)}` : `${numberWithCommas(v)} hrs`;
};

export const addHours = (v: string | number): string => {
  return `${numberWithCommas(v)} hrs`;
};

export const addDollars = (v: string | number): string => {
  return `$${numberWithCommas(v)}`;
};
