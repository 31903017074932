import React from 'react';
import styles from './PageTitle.module.scss';

interface Props {
  name: string;
  info: string;
  extraInfo?: string;
}

export const PageTitle: React.FC<Props> = ({ name, info, extraInfo }) => {
  return (
    <div className={styles.page_headline}>
      <h1 className="page-title">{name}</h1>
      <p className={styles.page_info}>
        <span className="text-nowrap">
          {info}
        </span>
        <span className={`text-nowrap ${styles.extra}`}>
          {extraInfo}
        </span>
      </p>
    </div>
  );
};
