import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { authRoutes } from '../../routing';
import { LoginPage } from './LoginPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import { CheckEmailPage } from './CheckEmailPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { RegistrationPage } from './RegistrationPage';
import { RegisterPhonePage } from './RegisterPhonePage';
import { VerifyEmailPage } from './VerifyEmailPage';
import { PasswordChangedPage } from './PasswordChangedPage';
import { AuthGoogle } from './AuthGoogle';
import { VerifyInvitePage } from './VerifyInvitePage';
import { TwoStepVerificationPhonePage } from './TwoStepVerificationPhonePage';

export const AuthRoutes: React.FC = observer(() => {
  return (
    <Switch>
      <Route exact path={authRoutes.LOGIN} component={LoginPage} />
      <Route exact path={authRoutes.GOOGLE} component={AuthGoogle} />
      <Route exact path={authRoutes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
      <Route exact path={authRoutes.RESET_PASSWORD} component={ResetPasswordPage} />
      <Route exact path={authRoutes.SING_UP} component={RegistrationPage} />
      <Route exact path={authRoutes.REGISTER_PHONE} component={RegisterPhonePage} />
      <Route exact path={authRoutes.VERIFY_PHONE} component={TwoStepVerificationPhonePage} />
      <Route exact path={authRoutes.VERIFY_EMAIL} component={VerifyEmailPage} />
      <Route exact path={authRoutes.CHECK_EMAIL} component={CheckEmailPage} />
      <Route exact path={authRoutes.PASSWORD_CHANGED} component={PasswordChangedPage} />
      <Route exact path={authRoutes.VERIFY_INVITE} component={VerifyInvitePage} />
    </Switch>
  );
});
