import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import './ProjectTable.scss';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Menu } from '../Menu';
import { ProjectAgencyEntity } from '../../store/entities/project-entities';
import { observer } from 'mobx-react-lite';
import { sortFunc, remainingFormatter, progressFormatter } from './fotmatters';

interface Props {
  items: ProjectAgencyEntity[];
}

export const ProjectAgencyTable: React.FC<Props> = observer(({ items }) => {
  return (
    <div className="table-area">
      <div className="custom-table">
        <BootstrapTable keyField="id" data={items} version="4" hover bordered={false} scrollTop={'Top'}>
          <TableHeaderColumn dataField="company" sortFunc={sortFunc} width="15rem">
            Company
          </TableHeaderColumn>
          <TableHeaderColumn dataField="used" dataSort sortFunc={sortFunc} width="6rem" dataAlign="right">
            Used
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField=""
            dataFormat={() => <span className="c-gray">of</span>}
            width="2.5rem"
            dataAlign="center"
          />
          <TableHeaderColumn dataField="budget" dataSort sortFunc={sortFunc} width="6rem">
            Budget
          </TableHeaderColumn>
          <TableHeaderColumn
            sortHeaderColumnClassName={(order) => order}
            dataField="value"
            width="7rem"
            dataFormat={progressFormatter}
            dataAlign="center"
          />
          <TableHeaderColumn
            dataField="remaining"
            dataSort
            sortFunc={sortFunc}
            width="8rem"
            dataFormat={remainingFormatter(items)}
          >
            Remaining
          </TableHeaderColumn>
          <TableHeaderColumn dataField="costs" dataSort sortFunc={sortFunc} width="5rem">
            Costs
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField=""
            width="2.5rem"
            dataFormat={(_: any, row: ProjectAgencyEntity) => <Menu {...row} />}
            tdStyle={{ overflow: 'visible' }}
          />
        </BootstrapTable>
      </div>
    </div>
  );
});
