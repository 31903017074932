import React from 'react';
import { Row, Col } from 'reactstrap';
import { SubMenu } from '../../components/SubMenu';
import { ProjectMenu } from '../../components/ProjectMenu';
import { Helmet } from 'react-helmet';

import { useAppStore } from 'store/app-store.hook';
import { QuicklinkPopup } from 'components/Quicklink/QuicklinkPopup';

import { QuicklinkList } from 'components/Quicklink/QuicklinkList';
import { links } from '../../store/project-quicklink-store/mocks';

const noop = () => undefined;

export const ProjectQuicklinksPage = () => {
  const { projectOverviewStore } = useAppStore();

  return (
    <div>
      <Helmet>
        <title>Construction SaaS | Quicklinks</title>
      </Helmet>
      <div className="top-panel mb-14">
        <div className="top-bar d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-top">
          <div className="d-xl-inline-block">
            {/* <ProjectTitle name={'Construction SaaS'} agency={'MindTrust'} client={'Clearbuild Inc.'} /> */}
          </div>
          <div className="submenu-holder text-nowrap">
            <SubMenu
              items={[
                {
                  label: 'Overview',
                  href: `/projects/${projectOverviewStore.projectId}`,
                },
                {
                  label: 'Details',
                  href: `/projects/${projectOverviewStore.projectId}/timeline`,
                },
                {
                  label: 'Reports',
                  href: `/projects/${projectOverviewStore.projectId}/reports`,
                },
                {
                  label: 'Invoices',
                  href: `/projects/${projectOverviewStore.projectId}/invoices`,
                },
                {
                  label: 'Quicklinks',
                  href: `/projects/${projectOverviewStore.projectId}/quicklinks`,
                },
              ]}
            />
          </div>
          <div className="text-right">
            <ProjectMenu />
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <QuicklinkList links={links} />
          {/* <div className="mh-custom text-center d-flex align-items-center justify-content-center pb-100">
            <QuicklinkBox />
          </div> */}
        </Col>
      </Row>
      <QuicklinkPopup onSend={noop} />
    </div>
  );
};
