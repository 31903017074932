import React from 'react';
import { observer } from 'mobx-react-lite';
import { TwoStepVerificationPhoneForm } from '../../components/Auth/TwoStepVerificationPhoneForm';
import { useAppStore } from '../../store/app-store.hook';
import { Helmet } from 'react-helmet';

export const TwoStepVerificationPhonePage: React.FC = observer(() => {
  const { authStore } = useAppStore();

  React.useEffect(() => {
    authStore.requestCode();
  }, [authStore]);

  return (
    <>
      <Helmet>
        <title>Two Step Verification Phone | MindTrust</title>
      </Helmet>
      <div>
        <TwoStepVerificationPhoneForm
          error={authStore.error}
          onVerify={authStore.onTwoFA}
          sendSms={authStore.requestCode}
        />
      </div>
    </>
  );
});
