import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const MyTimeOffPage = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>Time | My Time Off</title>
      </Helmet>
      <div>{`Hello ${location.pathname}`}</div>
    </>
  );
};
