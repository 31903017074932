import React from 'react';

import { CheckEmail } from '../../components/Auth/CheckEmail';
import { Helmet } from 'react-helmet';

export const CheckEmailPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Check Email | MindTrust</title>
      </Helmet>
      <div>
        <CheckEmail />
      </div>
    </>
  );
};
