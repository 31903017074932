import _ from 'lodash';

export interface Paging {
  limit: number;
  offset: number;
}

export interface FilterInput {
  [key: string]: { [key: string]: boolean };
}

export interface Predicate {
  field: string;
  operator: string;
  value: string;
}

export const removeFalsy = (filters: FilterInput) => {
  return _.omitBy(
    _.transform(filters, (result: FilterInput, value, key) => {
      result[key] = _.isObject(value) ? _.omitBy(value, (v) => !v) : value;
    }),
    _.isEmpty,
  );
};
