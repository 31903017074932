import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { contactsRoutes } from '../../routing/routes';
import { ContactsPage } from './ContactsPage';
import { UsersPage } from './UsersPage';
import { TeamsPage } from './TeamsPage';
import { ApplicantsRecruitsPage } from './ApplicantsRecruitsPage';
import { SalesTeamPage } from './SalesTeamPage';

export const ContactsRoutes: React.FC = observer(() => {
  const { contacts } = useAppStore().permissionStore;
  return (
    <Switch>
      {contacts.contacts && <Route exact path={contactsRoutes.CONTACTS} component={ContactsPage} />}
      {contacts.users && <Route exact path={contactsRoutes.USERS} component={UsersPage} />}
      {contacts.teams && <Route exact path={contactsRoutes.TEAMS} component={TeamsPage} />}
      {contacts.applicantsRecruits && (
        <Route exact path={contactsRoutes.APPLICANTS_RECRUITS} component={ApplicantsRecruitsPage} />
      )}
      {contacts.salesTeam && <Route exact path={contactsRoutes.SALES_TEAM} component={SalesTeamPage} />}
    </Switch>
  );
});
