import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { billingRoutes } from '../../routing/routes';
import { BillingPage } from './BillingPage';
import { MyPaymentsPage } from './MyPaymentsPage';
import { TeamPaymentsPage } from './TeamPaymentsPage';
import { SalesRepPaymentsPage } from './SalesRepPaymentsPage';

export const BillingRoutes: React.FC = observer(() => {
  const { billing } = useAppStore().permissionStore;
  return (
    <Switch>
      {billing.billing && <Route exact path={billingRoutes.BILLING} component={BillingPage} />}
      {billing.myPayments && <Route exact path={billingRoutes.MY_PAYMENTS} component={MyPaymentsPage} />}
      {billing.teamPayments && <Route exact path={billingRoutes.TEAM_PAYMENTS} component={TeamPaymentsPage} />}
      {billing.salesRepPayments && (
        <Route exact path={billingRoutes.SALES_REP_PAYMENTS} component={SalesRepPaymentsPage} />
      )}
    </Switch>
  );
});
