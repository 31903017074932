import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const PendingPage: React.FC = () => {
  const location = useLocation();
  return (
    <div>
      <Helmet>
        <title>Projects | Pending</title>
      </Helmet>
      {`Hello ${location.pathname}`}
    </div>
  );
};
