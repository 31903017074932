import { camelToNormal, camelToKebab } from 'utils/text-utils';

interface NavTab {
  name: string;
  url: string;
}

export const permissionToTab = (permissions: { [key: string]: boolean }): NavTab[] =>
  Object.keys(permissions).reduce<{ name: string; url: string }[]>((acc, next) => {
    if (permissions[next]) {
      acc.push({
        name: camelToNormal(next),
        url: `/${camelToKebab(next)}`,
      });
    }
    return acc;
  }, []);

export const permissionToTabNew = (permissions: { [key: string]: { [key: string]: boolean } }): NavTab[] =>
  Object.keys(permissions).reduce<{ name: string; url: string }[]>((acc, next) => {
    if (Object.values(permissions[next]).some((v) => !!v)) {
      acc.push({
        name: camelToNormal(next),
        url: `/${camelToKebab(next)}`,
      });
    }
    return acc;
  }, []);
