import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';

import { ViewAs } from '../ViewAs';
import { Menu } from '../Menu';
import { Filter } from 'components/Filter';

import './ProjectsTable.scss';
import styles from './ProjectsTable.module.scss';
import { InputGroup, Input } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useDebounce } from 'use-debounce';
import { ProjectListEntity } from '../../store/entities/project-list-entitiy';
import { useAppStore } from 'store/app-store.hook';
import queryString from 'query-string';
import { nameFormatter, progressFormatter, remainingFormatter, typeFormatter } from './formatters';

interface Props {
  total: number;
  projects: ProjectListEntity[];
  onSearch: (input: string) => void;
  onFilter: (params: any) => void;
  onSort: (params: any, next: any) => void;
}

const pushPaging = (page: number, sizePerPage: number) =>
  queryString.stringify({ offset: (page - 1) * sizePerPage, limit: sizePerPage });

export const ProjectsTable: React.FC<Props> = observer(({ total, projects, onSearch, onFilter, onSort }) => {
  const { routerStore } = useAppStore();
  const { pathname, search } = routerStore.location;
  const { limit = 10, offset = 0 } = queryString.parse(search);

  const [searchInput, setSearchInput] = React.useState('');
  const [value] = useDebounce(searchInput, 500);

  React.useEffect(() => {
    onSearch(value);
  }, [onSearch, value]);

  return (
    <div className="table-area">
      <div className="mlr-sm">
        <div className={`d-flex justify-content-between align-items-center ${styles.toolbar}`}>
          <div className="d-flex">
            <InputGroup className={styles.search_field}>
              <Input
                value={searchInput}
                type="search"
                placeholder="Search"
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </InputGroup>
            <Filter onFilter={onFilter} filters={{}} />
          </div>
          <ViewAs />
        </div>
      </div>
      <div>
        <div className="custom-table custom-bordered">
          <BootstrapTable
            data={projects}
            height="auto"
            version="4"
            hover
            pagination
            bordered={false}
            remote
            options={{
              sizePerPage: Number(limit) || 10,
              page: Number(offset) / Number(limit) + 1,
              noDataText: 'No data to display',
              onPageChange: (page: number, sizePerPage: number) => {
                routerStore.push(`${pathname}?${pushPaging(page, sizePerPage)}`);
              },
              onSortChange: onSort,
            }}
            fetchInfo={{ dataTotalSize: total }}
          >
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              isKey
              dataField="name"
              dataSort
              dataFormat={nameFormatter(projects)}
              width="12.5rem" // 16rem
              tdStyle={{ whiteSpace: 'normal' }}
            >
              Name
            </TableHeaderColumn>
            <TableHeaderColumn sortHeaderColumnClassName={(order) => order} dataField="status" dataSort width="4rem">
              Status
            </TableHeaderColumn>
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              dataField="used"
              dataSort
              width="6rem"
              dataAlign="right"
            >
              Used
            </TableHeaderColumn>
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              dataField=""
              dataFormat={() => <span className="c-gray">of</span>}
              width="2.5rem"
              dataAlign="center"
              editable={false}
            ></TableHeaderColumn>
            <TableHeaderColumn sortHeaderColumnClassName={(order) => order} dataField="budget" dataSort width="6rem">
              Budget
            </TableHeaderColumn>
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              dataField="type"
              dataSort
              width="8rem"
              dataFormat={typeFormatter}
              tdStyle={{ overflow: 'visible' }}
            >
              Type
            </TableHeaderColumn>
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              dataField="value"
              width="7rem"
              dataFormat={progressFormatter}
              dataAlign="center"
            ></TableHeaderColumn>
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              dataField="remaining"
              dataSort
              width="8rem"
              dataFormat={remainingFormatter(projects)}
              thStyle={{ textAlign: 'center' }}
              tdStyle={{ textAlign: 'right' }}
            >
              Remaining
            </TableHeaderColumn>
            <TableHeaderColumn sortHeaderColumnClassName={(order) => order} dataField="costs" dataSort width="6rem">
              Costs
            </TableHeaderColumn>
            <TableHeaderColumn
              sortHeaderColumnClassName={(order) => order}
              dataField=""
              width="2.85rem"
              dataFormat={(_: any, row: ProjectListEntity) => <Menu {...row} />}
              tdStyle={{ overflow: 'visible' }}
            ></TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </div>
  );
});
