import { ProjectsView, Project, ProjectTeam, ProjectClient, ProjectAgency } from 'types/project-types';
import { observable, computed } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { addDollars, addHours } from 'utils/text-utils';

export class ProjectEntity<T extends Project> {
  @observable protected project: T;
  @observable private toastStore: ToastStore;
  @observable private view: string | null;

  constructor(project: T, toastStore: ToastStore, view: string | null) {
    this.project = project;
    this.toastStore = toastStore;
    this.view = view;
  }

  edit = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} editing call...`);
  };

  changeStatus = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} change status call...`);
  };

  duplicate = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} duplicate call...`);
  };

  archive = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} archive call...`);
  };

  delete = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} delete call...`);
  };

  @computed
  get id() {
    return this.project.id;
  }

  @computed
  get used() {
    const { usedUsd, usedTaasHours } = this.project;
    return this.view === ProjectsView.TIME ? addHours(usedTaasHours) : addDollars(usedUsd);
  }

  @computed
  get budget() {
    const { budgetUsd, budgetTaasHours } = this.project;
    return this.view === ProjectsView.TIME ? addHours(budgetTaasHours) : addDollars(budgetUsd);
  }

  @computed
  get remaining() {
    const { remainingUsd, remainingTaasHours } = this.project;
    return this.view === ProjectsView.TIME ? addHours(remainingTaasHours) : addDollars(remainingUsd);
  }
  @computed
  get percentage() {
    const { fulfilmentUsd, fulfilmentTaasHours } = this.project;
    const v = this.view === ProjectsView.TIME ? fulfilmentTaasHours : fulfilmentUsd;
    return Math.round(v * 100);
  }

  @computed
  get costs() {
    const { costUsd, costTaasHours } = this.project;
    return this.view === ProjectsView.TIME ? addHours(costTaasHours) : addDollars(costUsd);
  }
}

export class ProjectTeamEntity extends ProjectEntity<ProjectTeam> {
  @computed
  get name() {
    const { firstName, lastName } = this.project;
    return `${firstName} ${lastName}`;
  }

  @computed
  get avatar() {
    return this.project.avatarUrl;
  }

  @computed
  get department() {
    return this.project.department;
  }
}

export class ProjectClientEntity extends ProjectEntity<ProjectClient> {}

export class ProjectAgencyEntity extends ProjectEntity<ProjectAgency> {}
