import gql from 'graphql-tag';

export const INVITE = gql`
  mutation InviteQL($input: InviteInput!) {
    invite(input: $input) {
      email
      roleName
      twoFactorEnabled
      createdAt
      status
    }
  }
`;
