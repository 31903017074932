import { Role } from 'types/gql-generated';

export const contacts = [Role.MT_SUPER_ADMIN_OWNER, Role.MT_ADMIN, Role.MT_FINANCE];

export const users = [Role.MT_SUPER_ADMIN_OWNER, Role.MT_ADMIN];

export const team = [Role.MT_SUPER_ADMIN_OWNER, Role.MT_ADMIN, Role.MT_FINANCE, Role.MT_RESOURCE_OPS];

export const applicantsRecruits = [Role.MT_SUPER_ADMIN_OWNER, Role.MT_ADMIN, Role.MT_FINANCE, Role.MT_RESOURCE_OPS];

export const salesTeam = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.SALES_REP_COMPANY_OWNER,
  Role.SALES_REP_COMPANY_ADMIN,
  Role.SALES_REP_COMPANY_BILLING_CONTACT,
];
