import { observable, computed } from 'mobx';
import { AuthStore } from '../auth-store';
import * as timePermissions from './time-permissions';
import * as companiesPermissions from './companies-permissions';
import * as contactsPermissions from './contacts-permissions';
import * as projectsPermissions from './projects-permissions';
import * as expensesPermissions from './expenses-permissions';
import * as referralsPermissions from './referrals-permissions';
import * as billingPermissions from './billing-permissions';
import * as subscriptionsPermissions from './subscriptions-permissions';
import * as documentsPermissions from './documents-permissions';
import * as globalSettingsPermissions from './global-settings-permissions';
import _ from 'lodash';

export class PermissionStore {
  @observable private _authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this._authStore = authStore;
  }

  @computed
  private get userRoles() {
    return this._authStore.me?.roles || [];
  }

  get gashboard() {
    return true;
  }

  get time(): { [key: string]: boolean } {
    return {
      myTimesheet: !!_.intersection(this.userRoles, timePermissions.myTimesheet).length,
      teamTimesheet: !!_.intersection(this.userRoles, timePermissions.teamTimesheets).length,
      mySchedule: !!_.intersection(this.userRoles, timePermissions.mySchedule).length,
      teamSchedule: !!_.intersection(this.userRoles, timePermissions.teamSchedule).length,
      companyCalendar: !!_.intersection(this.userRoles, timePermissions.companyCalendar).length,
      myCalendar: !!_.intersection(this.userRoles, timePermissions.myCalendar).length,
      myTimeOff: !!_.intersection(this.userRoles, timePermissions.myTimeOff).length,
      teamCalendars: !!_.intersection(this.userRoles, timePermissions.teamCalendars).length,
      teamTimeOff: !!_.intersection(this.userRoles, timePermissions.teamTimeOff).length,
    };
  }

  get companies() {
    return {
      companies: !!_.intersection(this.userRoles, companiesPermissions.companies).length,
      clients: !!_.intersection(this.userRoles, companiesPermissions.clients).length,
      prospects: !!_.intersection(this.userRoles, companiesPermissions.prospects).length,
      agencyResellers: !!_.intersection(this.userRoles, companiesPermissions.agencyResellers).length,
      agencyTalentPartners: !!_.intersection(this.userRoles, companiesPermissions.agencyTalentPartners).length,
      salesRepCompanies: !!_.intersection(this.userRoles, companiesPermissions.salesRepCompanies).length,
    };
  }

  get contacts() {
    return {
      contacts: !!_.intersection(this.userRoles, contactsPermissions.contacts).length,
      users: !!_.intersection(this.userRoles, contactsPermissions.users).length,
      teams: !!_.intersection(this.userRoles, contactsPermissions.team).length,
      applicantsRecruits: !!_.intersection(this.userRoles, contactsPermissions.applicantsRecruits).length,
      salesTeam: !!_.intersection(this.userRoles, contactsPermissions.salesTeam).length,
    };
  }

  get projects() {
    return {
      projects: {
        overview: !!_.intersection(this.userRoles, projectsPermissions.projects.overviewTab).length,
        details: !!_.intersection(this.userRoles, projectsPermissions.projects.detailsTab).length,
        reports: !!_.intersection(this.userRoles, projectsPermissions.projects.reportsTab).length,
        invoices: !!_.intersection(this.userRoles, projectsPermissions.projects.invoicesTab).length,
        quicklinks: !!_.intersection(this.userRoles, projectsPermissions.projects.quicklinksTab).length,
      },
      pending: {
        pending: !!_.intersection(this.userRoles, projectsPermissions.pending.main).length,
      },
      proposals: {
        main: !!_.intersection(this.userRoles, projectsPermissions.proposals.main).length,
      },
    };
  }

  get expenses() {
    return {
      myExpenses: !!_.intersection(this.userRoles, expensesPermissions.myExpenses).length,
      teamExpenses: !!_.intersection(this.userRoles, expensesPermissions.teamExpenses).length,
    };
  }

  get referrals() {
    return {
      shareOnline: !!_.intersection(this.userRoles, referralsPermissions.shareOnline).length,
      referredUsers: !!_.intersection(this.userRoles, referralsPermissions.referredUsers).length,
      commissions: !!_.intersection(this.userRoles, referralsPermissions.commissions).length,
      paymentOptions: !!_.intersection(this.userRoles, referralsPermissions.paymentOptions).length,
      expectedCommissions: !!_.intersection(this.userRoles, referralsPermissions.expectedCommissions).length,
      promoSalesCollateral: !!_.intersection(this.userRoles, referralsPermissions.promoSalesCollateral).length,
    };
  }

  get billing() {
    return {
      billing: !!_.intersection(this.userRoles, billingPermissions.billing).length,
      myPayments: !!_.intersection(this.userRoles, billingPermissions.myPayments).length,
      teamPayments: !!_.intersection(this.userRoles, billingPermissions.teamPayments).length,
      salesRepPayments: !!_.intersection(this.userRoles, billingPermissions.salesRepPayments).length,
    };
  }

  get subscriptions() {
    return {
      termsOfService: !!_.intersection(this.userRoles, subscriptionsPermissions.termsOfService).length,
    };
  }

  get documents() {
    return {
      faq: !!_.intersection(this.userRoles, documentsPermissions.FAQSupport).length,
      teamHandbook: !!_.intersection(this.userRoles, documentsPermissions.teamHandbook).length,
      talentPartnerHandbook: !!_.intersection(this.userRoles, documentsPermissions.talentPartnerHandbook).length,
      brandGuidelines: !!_.intersection(this.userRoles, documentsPermissions.brandGuidelines).length,
      salesRepCompanyHandbook: !!_.intersection(this.userRoles, documentsPermissions.salesRepCompanyHandbook).length,
      salesCollateral: !!_.intersection(this.userRoles, documentsPermissions.salesCollateral).length,
    };
  }

  get globalSettings() {
    return {
      userGroups: !!_.intersection(this.userRoles, globalSettingsPermissions.userGroups).length,
      departments: !!_.intersection(this.userRoles, globalSettingsPermissions.departments).length,
      projectRoles: !!_.intersection(this.userRoles, globalSettingsPermissions.projectRoles).length,
      expenseCategories: !!_.intersection(this.userRoles, globalSettingsPermissions.expenseCategories).length,
    };
  }
}
