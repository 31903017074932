import { GQLConnector } from 'store/qgl-connector';
import * as ReferralQueiries from '../../queries/referral-queries';
import { InviteQLVariables, InviteQL } from 'types/gql-generated';

export class ReferralService {
  private qglConnector: GQLConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }

  invite = async (input: InviteQLVariables['input']): Promise<InviteQL['invite']> =>
    (
      await this.qglConnector.mutate<InviteQL>({
        mutation: ReferralQueiries.INVITE,
        variables: { input },
      })
    ).invite;
}
