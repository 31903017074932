import { Role } from 'types/gql-generated';

export const projects = {
  overviewTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_TEAM_MEMBER,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
    Role.AGENCY_RESELLER_OWNER,
    Role.AGENCY_RESELLER_ADMIN,
    Role.AGENCY_RESELLER_BILLING_CONTACT,
    Role.AGENCY_RESELLER_USER,
    Role.AGENCY_TALENT_PARTNER_OWNER,
    Role.AGENCY_TALENT_PARTNER_ADMIN,
    Role.AGENCY_TALENT_PARTNER_BILLING_CONTACT,
    Role.AGENCY_TALENT_PARTNER_USER,
    Role.CLIENT_OWNER,
    Role.CLIENT_ADMIN,
    Role.CLIENT_BILLING_CONTACT,
    Role.CLIENT_USER,
  ],
  detailsTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_TEAM_MEMBER,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
    Role.AGENCY_RESELLER_OWNER,
    Role.AGENCY_RESELLER_ADMIN,
    Role.AGENCY_RESELLER_BILLING_CONTACT,
    Role.AGENCY_RESELLER_USER,
    Role.AGENCY_TALENT_PARTNER_OWNER,
    Role.AGENCY_TALENT_PARTNER_ADMIN,
    Role.AGENCY_TALENT_PARTNER_BILLING_CONTACT,
    Role.AGENCY_TALENT_PARTNER_USER,
    Role.CLIENT_OWNER,
    Role.CLIENT_ADMIN,
    Role.CLIENT_BILLING_CONTACT,
    Role.CLIENT_USER,
  ],
  reportsTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_TEAM_MEMBER,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
    Role.AGENCY_RESELLER_OWNER,
    Role.AGENCY_RESELLER_ADMIN,
    Role.AGENCY_RESELLER_BILLING_CONTACT,
    Role.AGENCY_TALENT_PARTNER_OWNER,
    Role.AGENCY_TALENT_PARTNER_ADMIN,
    Role.AGENCY_TALENT_PARTNER_BILLING_CONTACT,
    Role.AGENCY_TALENT_PARTNER_USER,
    Role.CLIENT_OWNER,
    Role.CLIENT_ADMIN,
    Role.CLIENT_BILLING_CONTACT,
    Role.CLIENT_USER,
  ],
  invoicesTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
    Role.AGENCY_RESELLER_OWNER,
    Role.AGENCY_RESELLER_ADMIN,
    Role.AGENCY_RESELLER_BILLING_CONTACT,
  ],
  quicklinksTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_TEAM_MEMBER,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
    Role.AGENCY_RESELLER_OWNER,
    Role.AGENCY_RESELLER_ADMIN,
    Role.AGENCY_RESELLER_BILLING_CONTACT,
    Role.AGENCY_RESELLER_USER,
    Role.AGENCY_TALENT_PARTNER_OWNER,
    Role.AGENCY_TALENT_PARTNER_ADMIN,
    Role.AGENCY_TALENT_PARTNER_BILLING_CONTACT,
    Role.AGENCY_TALENT_PARTNER_USER,
    Role.CLIENT_OWNER,
    Role.CLIENT_ADMIN,
    Role.CLIENT_BILLING_CONTACT,
    Role.CLIENT_USER,
  ],
};

export const pending = {
  main: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  overviewTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  timelineTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  invoicesTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  quicklinksTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_TEAM_PM,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
};

export const proposals = {
  main: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  overviewTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  timelineTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  reportsTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  invoicesTab: [
    Role.MT_SUPER_ADMIN_OWNER,
    Role.MT_ADMIN,
    Role.MT_FINANCE,
    Role.MT_RESOURCE_OPS,
    Role.MT_SALES_ADMIN,
    Role.MT_SALES_MGR,
    Role.MT_SALES_REP,
    Role.MT_OUTSIDE_REP,
    Role.SALES_REP_COMPANY_OWNER,
    Role.SALES_REP_COMPANY_ADMIN,
    Role.SALES_REP_COMPANY_BILLING_CONTACT,
    Role.SALES_REP_COMPANY_REP,
  ],
  quicklinksTab: [Role.MT_SUPER_ADMIN_OWNER],
};
