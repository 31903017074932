import { UserStatusFG, AuthenticateQL, RegisterInput, EmailPasswordInput, Role, PhoneInput } from './gql-generated';
import { CodeInput } from './gql-generated/gql-inputs';

export type UserTokens = AuthenticateQL['authenticate'];
export type UserInfo = UserStatusFG;

export interface DecodedToken {
  email: string;
  userExists: boolean;
  twoFactorEnabled: boolean;
  roles: Role[];
  iat: number;
  exp: number;
}
// // roles: ROLE[];

export type LoginRequest = EmailPasswordInput;
export type LoginCodeRequest = CodeInput;
export type RegisterRequest = RegisterInput;
export type PhoneRequest = PhoneInput;
export type VerifyPhoneRequest = CodeInput;

export const MT_ROLES = [
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_OUTSIDE_REP,
  Role.MT_RESOURCE_OPS,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_TEAM_MEMBER,
  Role.MT_TEAM_PM,
];

export const CLIENT_ROLES = [Role.CLIENT_ADMIN, Role.CLIENT_BILLING_CONTACT, Role.CLIENT_OWNER, Role.CLIENT_USER];

export const SALES_ROLES = [
  Role.SALES_REP_COMPANY_ADMIN,
  Role.SALES_REP_COMPANY_BILLING_CONTACT,
  Role.SALES_REP_COMPANY_OWNER,
  Role.SALES_REP_COMPANY_REP,
];

export const AGENCY_ROLES = [
  Role.AGENCY_RESELLER_ADMIN,
  Role.AGENCY_RESELLER_BILLING_CONTACT,
  Role.AGENCY_RESELLER_OWNER,
  Role.AGENCY_RESELLER_USER,
  Role.AGENCY_TALENT_PARTNER_ADMIN,
  Role.AGENCY_TALENT_PARTNER_BILLING_CONTACT,
  Role.AGENCY_RESELLER_OWNER,
  Role.AGENCY_TALENT_PARTNER_USER,
];

export const SYSTEM_ROLES = [
  Role.SYSTEM_LOGIN_CODE,
  Role.SYSTEM_REFRESH_TOKEN,
  Role.SYSTEM_REGISTER_PHONE,
  Role.SYSTEM_REQUEST_CODE,
  Role.SYSTEM_RESET_PASSWORD,
  Role.SYSTEM_VERIFY_EMAIL,
  Role.SYSTEM_VERIFY_INVITE,
  Role.SYSTEM_VERIFY_PHONE,
];

export interface InviteVerifyRequest {
  firstName: string;
  lastName: string;
  password: string;
}

export interface PhoneRegisterRequest {
  phone: string;
  countryCode: number;
}

export interface PhoneVerifyRequest {
  code: string;
}

export interface SMSRequest {}

export interface ConfirmRequest {
  code: number;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ResetPasswordRequest {
  password: string;
}

export interface ChangePasswordRequest {
  password: string;
}

export interface ChangeFullnameRequest {
  firstName: string;
  lastName: string;
}

export interface RefreshTokenRequest {
  refreshToken: string;
}

export interface AuthError {
  error: string;
  message: string;
  statusCode: number;
}
