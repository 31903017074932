import React from 'react';
import cn from 'classnames';
import { Nav } from 'reactstrap';
import { AppSidebar, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer } from '@coreui/react';
import { Link } from 'react-router-dom';
import { AccountDropdown } from '../containers/Layout/AccountDropdown';
import { useAppStore } from 'store/app-store.hook';
import { NavItem } from 'store/navigation-store';
import { ReactComponent as Logo } from '../../svg/mt-logo.svg';
import './LeftNavigation.scss';
import { observer } from 'mobx-react-lite';

const isActive = (path: string, navItem: NavItem): boolean => {
  return navItem.url === path || !!navItem?.children?.find((ch) => ch.url === path);
};

export const LeftNavigation: React.FC = observer(() => {
  const { authStore, navigationStore, routerStore } = useAppStore();
  const { location } = routerStore;
  const { items = [] } = navigationStore.navigation;
  const onSelect = React.useCallback(
    (navItem: NavItem) => () => {
      navigationStore.setSelected(navItem);
    },
    [navigationStore],
  );
  const showSecondary = (navigationStore.selected?.children?.length || 0) > 1;
  return (
    <>
      <AppSidebar className="sidebar--main" display="lg" fixed>
        <AppSidebarHeader />
        <AppSidebarForm />
        <strong className="logo">
          <a href="./" className="navbar-brand">
            <Logo />
          </a>
        </strong>
        <ul className="nav sidebar-nav">
          {items.map((i) => (
            <li className="nav-item" onClick={onSelect(i)} key={i.name}>
              <span className={cn('nav-link', { active: isActive(location.pathname, i) })}>
                <i className={`nav-icon ${i.icon}`} />
                <strong>{i.name}</strong>
              </span>
            </li>
          ))}
        </ul>
        <Nav className="mt-auto dropdown-center" navbar>
          <AccountDropdown onLogout={authStore.logout} accnt />
        </Nav>
      </AppSidebar>
      {showSecondary && (
        <AppSidebar className="sidebar--secondary" fixed>
          <div className="sidebar__title">
            <h2>MindTrust</h2>
            <h3>{navigationStore.selected?.name}</h3>
          </div>
          <ul className="nav sidebar-nav">
            {navigationStore.selected?.children?.map((ch) => (
              <li className="nav-item" key={ch.name}>
                <Link to={ch.url} className={cn('nav-link', { active: isActive(location.pathname, ch) })}>
                  <strong>{ch.name}</strong>
                </Link>
              </li>
            ))}
          </ul>
          <AppSidebarMinimizer />
        </AppSidebar>
      )}
    </>
  );
});
