import React from 'react';
import styles from './QuicklinkPopup.module.scss';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, FormFeedback } from 'reactstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validate, validators } from 'utils/form-utils';
import { QuicklinkCreate } from '../../../types/project-types';
import { Loader } from 'components/Loader';

interface FormValues {
  title: string;
  url: string;
}

interface QuicklinkFormProps {
  error?: string;
  onCancel?: () => void;
  onSend: (params: QuicklinkCreate) => void;
}

const validationSchema = () =>
  Yup.object().shape({
    title: validators.title,
    url: validators.url,
  });

const initialValues = {
  title: '',
  url: '',
};

export const QuicklinkPopup: React.FC<QuicklinkFormProps> = ({ onCancel, onSend, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onSend,
    isInitialValid: false,
    // @ts-ignore
    validate: validate<FormValues>(validationSchema),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;

  return (
    <div className={`${styles.popup_holder} d-flex align-items-center justify-content-center`}>
      <Card className={styles.popup}>
        <CardHeader>
          <h3>Create Quicklink</h3>
          <p>Quicklinks allow you to easily access a url relavant to the project.</p>
        </CardHeader>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <CardBody className="mh-371">
            <FormGroup>
              <label htmlFor="title">
                Quicklink Title
              </label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="What would you like to call it?"
                autoComplete="title"
                valid={touched.title && !errors.title}
                invalid={touched.title && !!errors.title}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
              />
              <FormFeedback>{errors.title}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label htmlFor="url">
                Quicklink URL
              </label>
              <Input
                type="url"
                name="url"
                id="url"
                placeholder="Please paste the entire url here"
                autoComplete="url"
                valid={touched.url && !errors.url}
                invalid={touched.url && !!errors.url}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
              />
              <FormFeedback>{errors.url}</FormFeedback>
            </FormGroup>
            <div className={styles.quicklink_placeholder}>
              <div className="fadeIn loader-inline">
                <Loader /><span className="loader-title">Loading preview..</span>
              </div>
            </div>
          </CardBody>
          <CardFooter className="text-right">
            <Button type="button" color="outline-secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="button" color="primary" className="ml-10" disabled={!isValid} onClick={handleSubmit}>
              Create Quicklink
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </div>
  );
};
