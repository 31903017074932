import React from 'react';

import styles from './ProgressRange.module.scss';

interface Props {
  value: number;
  valueSpent: number;
  valueMax: number;
}

export const ProgressRange: React.FC<Props> = ({ value, valueSpent, valueMax }) => {
  const style = { width: `${value}%` };
  return (
    <div className={styles.progress_holder}>
      <span className={styles.progress_label}>{valueSpent} of {valueMax}</span>
      <div className={styles.progress_bar}>
        <div style={style} />
      </div>
    </div>
  );
};
