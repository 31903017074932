import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import './SubMenu.scss';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';

interface Props {
  items: {
    href: string;
    label: string;
  }[];
}

const isActive = (pathname: string, href: string) => pathname === href;

export const SubMenu: React.FC<Props> = observer(({ items }) => {
  const { pathname } = useAppStore().routerStore.location;
  return (
    <Nav className="sub-menu">
      {items.map(({ label, href }) => (
        <NavItem key={href}>
          <Link className={isActive(pathname, href) ? 'active' : ''} to={href}>
            {label}
          </Link>
        </NavItem>
      ))}
    </Nav>
  );
});
