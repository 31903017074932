import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { MutationOptions, QueryOptions, ApolloError } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { FetchResult, ApolloLink } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import { API_URI } from '../config';
import { readTokenToLocalStorage, saveTokenToLocalStorage } from 'utils/local-storage.utils';
import jwt_decode from 'jwt-decode';
import { AuthenticateQL, AuthenticationScheme } from 'types/gql-generated';
import * as AuthQueriers from 'queries/auth-queries';

const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
const cleanupResponse = (res: Object) => JSON.parse(JSON.stringify(res), omitTypename);
const getLink = (options: { uri: string; headers?: { [key: string]: string } }) =>
  ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink(options),
    ApolloLink.from([
      new ApolloLink((operation, forward) => {
        if (operation.variables) {
          operation.variables = cleanupResponse(operation.variables);
        }
        return forward(operation).map((data) => {
          return data;
        });
      }),
      new HttpLink(options),
    ]),
  );

export class GQLConnector {
  static makeFetch(uri: string, options: RequestInit) {
    return fetch(uri, { ...options, credentials: 'include' });
  }

  static hasErrorCode(errors: ReadonlyArray<GraphQLError> | null, code: number): boolean {
    return !!(errors && errors.find((e) => e.extensions?.status === code));
  }

  processErrorCode(errors: ReadonlyArray<GraphQLError> | null) {
    if (GQLConnector.hasErrorCode(errors, 401)) {
      console.error('Not Authorized (401) - ', errors);
      
    }

    if (GQLConnector.hasErrorCode(errors, 403)) {
      console.error('Not Authorized (403) - ', errors);
    }

    if (GQLConnector.hasErrorCode(errors, 422)) {
      return console.error('Not connected (422) - ', errors);
    }

    if (errors && errors.length) {
      return console.error('API Error - ', errors);
    }
  }

  private resolveExtensions = (data?: string | string[]) => {
    if (!data) return '';
    if (typeof data === 'string') return data;
    else return (data as string[]).map((m: string) => `${m}\n`);
  };

  private getErrorMessage = (errors?: readonly GraphQLError[]): string => {
    if (!errors) return '';
    return errors
      .map(({ extensions }) => `Error: ${this.resolveExtensions(extensions?.data)} (ID: ${extensions?.id})`)
      .join(';\n');
  };

  private handleError = ({ graphQLErrors, networkError }: ApolloError) => {
    this.processErrorCode(graphQLErrors);
    throw new Error(this.getErrorMessage(graphQLErrors) || networkError?.message);
  };

  private handleResponse = <T>(result: FetchResult<T>): T => {
    if (!result.data) {
      this.processErrorCode(result.errors || null);
      throw new Error(this.getErrorMessage(result.errors));
    }
    return result.data;
  };

  mutate = async <T>(mutation: MutationOptions<T>): Promise<T> => {
    const client = await this.createHttpClient();
    return client.mutate<T>(mutation).then(this.handleResponse, this.handleError);
  };

  query = async <T, O>(query: QueryOptions<O>): Promise<T> => {
    const client = await this.createHttpClient();
    return client
      .query({ ...query, fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .then(this.handleResponse, this.handleError)
      .then(cleanupResponse);
  };

  updateTokens = async (refreshToken: string | null) =>
    (
      await this.mutate<AuthenticateQL>({
        mutation: AuthQueriers.AUTHENTICATE,
        variables: {
          input: {
            scheme: AuthenticationScheme.REFRESH_TOKEN,
            refreshToken,
          },
        },
      })
    ).authenticate;

  getFreshToken = async (): Promise<string | null> => {
    const tokens = readTokenToLocalStorage();
    const accessToken = tokens?.token;
    const refreshToken = tokens?.refreshToken || null;
    if (!accessToken) return null;
    const { exp } = jwt_decode(accessToken);
    if (exp * 1000 >= Date.now()) return accessToken;
    return saveTokenToLocalStorage(await this.updateTokens(refreshToken)).refreshToken;
  };

  private async createHttpClient() {
    const token = await this.getFreshToken();
    return new ApolloClient<any>({
      cache: new InMemoryCache(),
      link: getLink({
        uri: `${API_URI}/graphql`,
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
    });
  }
}
