import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { SuccessIcon } from '../../components/SvgIcons/Success';
import { Helmet } from 'react-helmet';

export const PasswordChangedPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Password Changed | MindTrust</title>
      </Helmet>
      <Card className="d-flex flex-row align-items-center mh-315 w-23">
        <CardBody>
          <div className="text-center">
            <div className="icon mb-med">
              <SuccessIcon className="icon" />
            </div>
            <div className="card-headline text-center">
              <h1>Your password been successfuly changed!</h1>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
