import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import 'normalize-scss';
import 'react-datasheet/lib/react-datasheet.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './scss/style.scss';
import 'mobx-react-lite/batchingForReactDom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

const WithHotReload = process.env.NODE_ENV === 'production' ? App : hot(App);

ReactDOM.render(
  <React.StrictMode>
    <WithHotReload />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
