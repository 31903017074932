import React from 'react';

interface Props {
  className?: string;
}

export const EmailIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        d="M13.125 0H0.875009C0.391738 0 0 0.373084 0 0.833343V9.16666C0 9.62686 0.391738 10 0.875009 10H13.125C13.6083 10 14 9.62686 14 9.16666V0.833343C14 0.373084 13.6083 0 13.125 0ZM6.99998 5.83094L1.74999 3.73865V1.93096L6.99998 4.01303L12.25 1.93096V3.73865L6.99998 5.83094Z"
      />
    </svg>
  );
};
