import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { documentsRoutes } from '../../routing/routes';
import { TeamHandbookPage } from './TeamHandbookPage';
import { BrandGuidelinesPage } from './BrandGuidelinesPage';
import { SalesCollateralPage } from './SalesCollateralPage';

export const DocumentsRoutes: React.FC = observer(() => {
  const { documents } = useAppStore().permissionStore;
  return (
    <Switch>
      {documents.teamHandbook && <Route exact path={documentsRoutes.TEAM_HANDBOOK} component={TeamHandbookPage} />}
      {documents.brandGuidelines && (
        <Route exact path={documentsRoutes.BRAND_GUIDELINES} component={BrandGuidelinesPage} />
      )}
      {documents.salesCollateral && (
        <Route exact path={documentsRoutes.SALES_COLLATERAL} component={SalesCollateralPage} />
      )}
    </Switch>
  );
});
