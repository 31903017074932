import { AuthService } from './services/auth-service';
import { AuthenticationScheme, Role, VerifyInviteInput } from 'types/gql-generated';
import {
  LoginRequest,
  UserTokens,
  RegisterRequest,
  UserInfo,
  ResetPasswordRequest,
  DecodedToken,
  PhoneRequest,
  VerifyPhoneRequest,
} from 'types/auth-types';
import { observable, action, reaction, computed } from 'mobx';
import { saveTokenToLocalStorage, readTokenToLocalStorage, clearLocalStorage } from 'utils/local-storage.utils';
import { RouterStore } from 'mobx-react-router';
import { authRoutes } from 'routing';
import jwtDecode from 'jwt-decode';

export class AuthStore {
  private routerStore: RouterStore;
  private authService: AuthService;
  @observable private _userTokens: UserTokens | null = null;
  @observable private _me: UserInfo | null = null;
  @observable private _error: string | null = null;

  constructor(authService: AuthService, routerStore: RouterStore) {
    this._userTokens = readTokenToLocalStorage();
    this.authService = authService;
    this.routerStore = routerStore;
    reaction(() => {
      const roles = this._userTokens ? this._userTokens.roles : this._me?.roles;
      return { roles: roles || [], hasRefreshToken: !!this._userTokens?.refreshToken };
    }, this.checkRoles);
  }

  @computed
  get me() {
    return this._me;
  }

  get error() {
    return this._error || undefined;
  }

  @action
  initialize = async () => {
    try {
      if (this._userTokens) this._me = (await this.authService.me()).me;
    } catch {
      clearLocalStorage();
    }
  };

  @action
  onTwoFA = ({ code }: VerifyPhoneRequest) => {
    const isLogin = (this._userTokens?.roles || []).find((r) => r === Role.SYSTEM_LOGIN_CODE);
    return isLogin ? this.loginWithCode({ code }) : this.verifyPhone({ code });
  };

  @action
  checkRoles = async ({ roles = [], hasRefreshToken }: { roles: Role[]; hasRefreshToken: boolean }) => {
    this._error = null;
    if (roles.find((r) => r === Role.SYSTEM_REGISTER_PHONE)) {
      return this.routerStore.push(authRoutes.REGISTER_PHONE);
    }
    if (roles.find((r) => r === Role.SYSTEM_REQUEST_CODE)) {
      return this.routerStore.push(authRoutes.VERIFY_PHONE);
    }
    if (roles.find((r) => r === Role.SYSTEM_LOGIN_CODE)) {
      return this.routerStore.push(authRoutes.VERIFY_PHONE);
    }
    if (hasRefreshToken) {
      this._me = (await this.authService.me()).me;
    }
    return this.routerStore.push('/');
  };

  @action
  login = async ({ email, password }: LoginRequest) => {
    try {
      clearLocalStorage();
      const userTokens = await this.authService.authenticate({
        scheme: AuthenticationScheme.EMAIL_PASSWORD,
        emailPassword: { email, password },
      });
      this._userTokens = saveTokenToLocalStorage(userTokens);
    } catch (error) {
      clearLocalStorage();
      this._error = error.message;
    }
  };

  @action
  loginWithCode = async ({ code }: VerifyPhoneRequest) => {
    try {
      clearLocalStorage();
      const userTokens = await this.authService.authenticate({
        scheme: AuthenticationScheme.TOKEN_CODE,
        tokenCode: { code, token: this._userTokens!.token },
      });
      this._userTokens = saveTokenToLocalStorage(userTokens);
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  loginWithGoogle = async (googleCode: string) => {
    try {
      clearLocalStorage();
      const userTokens = await this.authService.authenticate({
        scheme: AuthenticationScheme.GOOGLE,
        googleCode,
      });
      this._userTokens = saveTokenToLocalStorage(userTokens);
    } catch (error) {
      clearLocalStorage();
      this._error = error.message;
    }
  };

  @action
  register = async (input: RegisterRequest) => {
    try {
      const me = await this.authService.register(input);
      this._me = me;
      this.routerStore.push(authRoutes.CHECK_EMAIL);
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  verifyEmail = async (token: string) => {
    try {
      saveTokenToLocalStorage({ token, refreshToken: '', roles: [] });
      const { authentication, ...me } = await this.authService.verifyEmail();
      this._userTokens = saveTokenToLocalStorage(authentication);
      this._me = me;
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  verifyInvite = async (token: string, register: VerifyInviteInput) => {
    try {
      saveTokenToLocalStorage({ token, refreshToken: null, roles: [] });
      const { authentication, ...me } = await this.authService.verifyInvite(register);
      this._userTokens = saveTokenToLocalStorage(authentication);
      this._me = me;
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  forgotPassword = async (email: string) => {
    try {
      await this.authService.requestResetPassword({ email });
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  resetPassword = async (token: string, { password }: ResetPasswordRequest) => {
    try {
      saveTokenToLocalStorage({ token, refreshToken: '', roles: [] });
      await this.authService.resetPassword({ password });
      return true;
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  registerPhone = async (input: PhoneRequest) => {
    try {
      const { authentication, ...me } = await this.authService.registerPhone(input);
      this._userTokens = saveTokenToLocalStorage(authentication);
      this._me = me;
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  requestCode = async () => {
    try {
      await this.authService.requestCode();
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  verifyPhone = async (input: VerifyPhoneRequest) => {
    try {
      const { authentication, ...me } = await this.authService.verifyPhone(input);
      this._userTokens = saveTokenToLocalStorage(authentication);
      this._me = me;
    } catch (error) {
      this._error = error.message;
    }
  };

  @action
  logout = () => {
    this._me = null;
    clearLocalStorage();
    this.routerStore.push('/');
  };

  decodeToken = (token: string = ''): DecodedToken => {
    return jwtDecode<DecodedToken>(token);
  };
}
