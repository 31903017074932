import React from 'react';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { Promo } from 'components/Promo';
import { CheckEmailIcon } from '../../SvgIcons/CheckEmail';
import { Button } from 'reactstrap';
import { PromoReview } from '../../../types/project-request-types';

interface Props {
  email: string;
  onResend: () => void;
  promoReview: PromoReview;
}

export const StartProject6: React.FC<Props> = ({ email, onResend, promoReview }) => {
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-progress-line">
          <div className="auth-progress-value step-6"></div>
        </div>
        <div className="auth-card card-wide">
          <div className="auth-box d-flex flex-column">
            <div className="auth-headline mb-0">
              <strong className="auth-logo">
                <Logo />
              </strong>
            </div>
            <div className="auth-message text-center">
              <CheckEmailIcon />
              <h1 className="mt-20">Thanks! Chat soon</h1>
              <p>
                We’ve sent a confirmation email to
                <br /> {email}
              </p>
            </div>
          </div>
          <p className="help-text pt-48">
            Didn’t receive an email?{' '}
            <Button color="link" onClick={onResend}>
              Click to resend
            </Button>
          </p>
        </div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">
        <Promo {...promoReview} />
      </div>
    </div>
  );
};
