import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Promo } from 'components/Promo';
import { validate } from 'utils/form-utils';
import { SelectOption } from 'types/select-types';
import { requestProjectRoutes } from 'routing';
import { PromoReview } from 'types/project-request-types';

const animatedComponents = makeAnimated<SelectOption<string>>();

interface FormValues {
  projectSolutionName: string | null;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      projectSolutionName: Yup.string().required(),
    })
    .required();

const initialValues = {
  projectSolutionName: null,
};

interface Props {
  error?: string;
  initial: {
    projectSolutionName?: string | null;
  };
  solutionsOptions: SelectOption<string>[];
  promoReview: PromoReview;
  onSend: (params: FormValues) => void;
  goBack: () => void;
}

export const StartProject2ProjectBased: React.FC<Props> = ({
  onSend,
  goBack,
  initial,
  solutionsOptions,
  promoReview,
  error,
}) => {
  const { search } = useLocation();
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...initial,
    },
    onSubmit: onSend,
    validate: validate<FormValues>(validationSchema),
  });
  const { values, errors, touched, handleSubmit, handleBlur, setFieldValue } = formik;

  const onSelect = React.useCallback(
    ({ value }: any) => {
      setFieldValue('projectSolutionName', value, true);
    },
    [setFieldValue],
  );

  const onCheck = React.useCallback(
    (key: string): ChangeEventHandler<{ value: string }> => () => {
      setFieldValue('projectSolutionName', key, true);
    },
    [setFieldValue],
  );

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-progress-line">
          <div className="auth-progress-value step-2"></div>
        </div>
        <div className="auth-card card-wide">
          <Form onSubmit={handleSubmit}>
            <div className="auth-box">
              <div className="auth-headline">
                <strong className="auth-logo">
                  <Logo />
                </strong>
                <h1>What type of project is it?</h1>
                <p>
                  I am looking for{' '}
                  <Link to={`${requestProjectRoutes.request(2)}/resources${search}`}>specific team members</Link>{' '}
                  instead.
                </p>
              </div>
              <FormGroup
                className={classNames(
                  'form-group-addon',
                  { 'input-active': values.projectSolutionName },
                  { 'input-invalid': touched.projectSolutionName && !!errors.projectSolutionName },
                  { 'input-touched': touched.projectSolutionName },
                  { 'input-valid': touched.projectSolutionName && !errors.projectSolutionName },
                )}
              >
                <Select
                  components={animatedComponents}
                  className="w-100 select-search select-container"
                  classNamePrefix="select"
                  options={solutionsOptions}
                  placeholder="Search for your solutions"
                  onChange={onSelect}
                  value={
                    values.projectSolutionName
                      ? {
                          label: values.projectSolutionName,
                          value: values.projectSolutionName,
                        }
                      : null
                  }
                />
                <FormFeedback>{errors.projectSolutionName}</FormFeedback>
              </FormGroup>
              <FormGroup className="checkbox-list">
                <span className="label">Popular services</span>
                <ul className="option-list">
                  {solutionsOptions.slice(0, 5).map((option) => (
                    <CustomInput
                      type="radio"
                      key={`${option.value}`}
                      id={`${option.value}`}
                      label={`${option.label}`}
                      checked={values.projectSolutionName === option.value}
                      onChange={onCheck(option.value)}
                      onBlur={handleBlur}
                    />
                  ))}
                </ul>
              </FormGroup>
            </div>
            <div className="auth-actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup>
                <Row className="gap-xs">
                  <Col xs={6}>
                    <Button className="btn-block c-gray" color="default" onClick={goBack}>
                      <i className="btn-icon-back" />
                      Back
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      className="btn-block"
                      type="submit"
                      color="primary"
                      disabled={!values.projectSolutionName}
                      onClick={handleSubmit}
                    >
                      Next<i className="btn-icon-next"></i>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <p className="help-text">
              Need help? <Link to="#">Speak with a Sales Consultant.</Link>
            </p>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">
        <Promo {...promoReview} />
      </div>
    </div>
  );
};
