import { Progress } from 'components/Progress';
import React from 'react';
import { SortOrder } from 'react-bootstrap-table';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ProjectEntity, ProjectTeamEntity } from 'store/entities/project-entities';
import naturalCompare from 'string-natural-compare';
import styles from './ProjectTable.module.scss';

export const remainingFormatter = (items: ProjectEntity<any>[]) => (cell: any, _: any, __: any, rowIndex: number) => (
  <>
    {cell} <span className="c-gray ml-2">{items[rowIndex].percentage}%</span>
  </>
);

export const sortFunc = (a: ProjectEntity<any>, b: ProjectEntity<any>, order: SortOrder, sortField: keyof object) => {
  return order === 'asc'
    ? naturalCompare(a[sortField], b[sortField], { caseInsensitive: true })
    : naturalCompare(b[sortField], a[sortField], { caseInsensitive: true });
};

export const departmentFormatter = (cell: any) => {
  const colorClassName = `${cell}` ? `tag tag--${cell}`.toLowerCase() : 'tag';
  return <strong className={colorClassName}>{cell}</strong>;
};

export const progressFormatter = (cell: number) => <Progress value={cell} />;

export const teamNameFormatter = (items: ProjectTeamEntity[]) => (cell: any, _: any, __: any, rowIndex: number) => {
  const colorClassName = `${items[rowIndex].department}` ? `color--${items[rowIndex].department}`.toLowerCase() : '';
  const { percentage, name, avatar } = items[rowIndex];
  return (
    <div className={`d-flex align-items-center ${styles.team_box} ${colorClassName}`}>
      <div className={`team-icon ${styles.team_avatar} ${colorClassName}`}>
        <span className={avatar ? 'team-ava' : ''}>
          <CircularProgressbarWithChildren strokeWidth={6} value={percentage}>
            {avatar ? <img src={avatar} alt={cell} /> : <i />}
          </CircularProgressbarWithChildren>
        </span>
      </div>
      <div>
        <strong className={styles.team_title}>{name}</strong>
        <em className={styles.team_name}>{avatar}</em>
      </div>
    </div>
  );
};
