import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { PromoReview, StartProjectRequest5 } from '../../../types/project-request-types';
import { NavLink } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { Promo } from 'components/Promo';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import { ChangeEventHandler } from 'react';
import moment from 'moment';

type FormValues = StartProjectRequest5;

const validationSchema = () =>
  Yup.object()
    .shape({
      whenToStart: Yup.string().nullable(),
      additionalDetails: Yup.string().nullable().notRequired(),
    })
    .required();

const initialValues = {
  whenToStart: moment().format('YYY-MM-DD'),
  additionalDetails: null,
};

interface StartProject5Props {
  error?: string;
  initial: FormValues;
  promoReview: PromoReview;
  onSend: (params: any) => void;
  goBack: () => void;
}

enum When {
  asap,
  selectDate,
  notSure,
}

export const StartProject5: React.FC<StartProject5Props> = ({ onSend, goBack, initial, error, promoReview }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...initial,
    },
    onSubmit: onSend,
    validate: validate<FormValues>(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;
  const [date, setDate] = React.useState<Moment | null>(values.whenToStart ? moment(values.whenToStart) : null);
  const [focus, setFocus] = React.useState<{ focused: boolean | null }>({ focused: false });
  const [when, setWhen] = React.useState(When.selectDate);
  const showDatePicker = React.useMemo(() => when === When.selectDate, [when]);

  const onCheck = React.useCallback(
    (key: When): ChangeEventHandler<{ value: string }> => () => {
      setWhen(key);
      if (key === When.asap) {
        setFieldValue('whenToStart', moment().format('YYY-MM-DD'), true);
        return;
      }
      if (key === When.selectDate) {
        setFieldValue('whenToStart', null, true);
        return;
      }
      if (key === When.notSure) {
        setFieldValue('whenToStart', null, true);
        return;
      }
    },
    [setFieldValue],
  );

  const onDateChange = (date: Moment | null) => {
    setDate(date);
    setFieldValue('whenToStart', moment(date).format('YYY-MM-DD'), true);
  };

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-progress-line">
          <div className="auth-progress-value step-5"></div>
        </div>
        <div className="auth-card card-wide">
          <Form onSubmit={handleSubmit}>
            <div className="auth-box">
              <div className="auth-headline">
                <strong className="auth-logo">
                  <Logo />
                </strong>
                <h1>When would you like to start?</h1>
              </div>
              <FormGroup className="custom-radio-buttons no-icons">
                <Row className="gap-cards">
                  <Col xs={4}>
                    <CustomInput
                      type="radio"
                      id="asap"
                      label="ASAP"
                      name="start"
                      required
                      checked={when === When.asap}
                      onChange={onCheck(When.asap)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      type="radio"
                      id="start_pickDate"
                      label="Pick a date"
                      name="start"
                      required
                      checked={when === When.selectDate}
                      onChange={onCheck(When.selectDate)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={4}>
                    <CustomInput
                      type="radio"
                      id="unsure"
                      label="Unsure"
                      name="start"
                      required
                      checked={when === When.notSure}
                      onChange={onCheck(When.notSure)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {showDatePicker && (
                <FormGroup
                  className={classNames(
                    'form-group-addon',
                    { 'input-active': values.additionalDetails },
                    { 'input-invalid': touched.additionalDetails && !!errors.additionalDetails },
                    { 'input-touched': touched.additionalDetails },
                    { 'input-valid': touched.additionalDetails && !errors.additionalDetails },
                  )}
                >
                  <label htmlFor="pickDate">Pick a date</label>
                  <SingleDatePicker
                    id="pickDate"
                    date={date}
                    onDateChange={onDateChange}
                    focused={focus.focused}
                    onFocusChange={setFocus}
                    numberOfMonths={1}
                    placeholder="Select start date"
                  />
                  <FormFeedback>{errors.additionalDetails}</FormFeedback>
                </FormGroup>
              )}
              <FormGroup
                className={classNames(
                  'form-group-addon',
                  { 'input-active': values.additionalDetails },
                  { 'input-invalid': touched.additionalDetails && !!errors.additionalDetails },
                  { 'input-touched': touched.additionalDetails },
                  { 'input-valid': touched.additionalDetails && !errors.additionalDetails },
                )}
              >
                <label htmlFor="additionalDetails">Additional details</label>
                <Input
                  className="form-control"
                  type="textarea"
                  name="additionalDetails"
                  id="additionalDetails"
                  placeholder="Are there any other information you’d like to share?"
                  valid={touched.additionalDetails && !errors.additionalDetails}
                  invalid={touched.additionalDetails && !!errors.additionalDetails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.additionalDetails || ''}
                />
                <FormFeedback>{errors.additionalDetails}</FormFeedback>
              </FormGroup>
            </div>
            <div className="auth-actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup>
                <Row className="gap-xs">
                  <Col xs={6}>
                    <Button className="btn-block c-gray" color="default" onClick={goBack}>
                      <i className="btn-icon-back" />
                      Back
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      className="btn-block"
                      type="submit"
                      color="primary"
                      disabled={!values.whenToStart && !date && when !== When.notSure}
                      onClick={handleSubmit}
                    >
                      Next<i className="btn-icon-next"></i>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <p className="help-text">
              Need help? <NavLink to="#">Speak with a Sales Consultant.</NavLink>
            </p>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">
        <Promo {...promoReview} />
      </div>
    </div>
  );
};
