import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { Promo } from 'components/Promo';
import Select from 'react-select';
import { PromoReview } from 'types/project-request-types';

interface FormValues {
  budget: string | null;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      budget: Yup.string().nullable(),
    })
    .required();

const initialValues = {
  budget: '0_5000',
};

interface StartProject4Props {
  error?: string;
  initial: FormValues;
  promoReview: PromoReview;
  onSend: (params: { budget: string | null }) => void;
  goBack: () => void;
}

const options = [
  { label: '$0 - $5,000 USD', value: '0_5000' },
  { label: '$5,000 - $25,000 USD', value: '5000_25000' },
  { label: '$25,000 - $50,000 USD', value: '25000_50000' },
  { label: '$50,000 - $100,000 USD', value: '50000_100000' },
  { label: '$100,000 - $250,000 USD', value: '100000_250000' },
  { label: '$250,000 - $500,000 USD', value: '250000_500000' },
  { label: '$500,000+', value: '500000_' },
];

export const StartProject4: React.FC<StartProject4Props> = ({ onSend, goBack, initial, error, promoReview }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...initial,
    },
    onSubmit: onSend,
    validate: validate(validationSchema),
  });

  const [knowBudget, setKnowBudget] = React.useState(true);
  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  const changeKnowBudget = React.useCallback(
    (flag: boolean) => () => {
      if (flag) setFieldValue('budget', '0_5000', true);
      if (!flag) setFieldValue('budget', null, true);
      setKnowBudget(flag);
    },
    [setFieldValue],
  );

  const onSelect = React.useCallback(
    (value) => {
      return setFieldValue('budget', value.value, true);
    },
    [setFieldValue],
  );

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-progress-line">
          <div className="auth-progress-value step-4"></div>
        </div>
        <div className="auth-card card-wide">
          <Form onSubmit={handleSubmit}>
            <div className="auth-box">
              <div className="auth-headline">
                <strong className="auth-logo">
                  <Logo />
                </strong>
                <h1>Do you have a budget in mind?</h1>
              </div>
              <FormGroup className="custom-radio-buttons">
                <Row className="gap-cards">
                  <Col xs={6}>
                    <CustomInput
                      className="custom-icon icon-budget"
                      type="radio"
                      id="yesBudget"
                      label="Yes I do"
                      name="budget"
                      required
                      checked={knowBudget}
                      onChange={changeKnowBudget(true)}
                    />
                  </Col>
                  <Col xs={6}>
                    <CustomInput
                      className="custom-icon icon-nobudget"
                      type="radio"
                      id="noBudget"
                      label="I don’t know"
                      name="budget"
                      required
                      checked={!knowBudget}
                      onChange={changeKnowBudget(false)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {knowBudget && (
                <FormGroup
                  className={classNames(
                    'form-group-addon',
                    { 'input-active': values.budget },
                    { 'input-invalid': touched.budget && !!errors.budget },
                    { 'input-touched': touched.budget },
                    { 'input-valid': touched.budget && !errors.budget },
                  )}
                >
                  <label htmlFor="budget">What is your budget?</label>
                  <Select
                    className="w-100 select-container"
                    classNamePrefix="select"
                    id="budget"
                    options={options}
                    placeholder="Select your range"
                    onChange={onSelect}
                    value={options.find(({ value }) => value === values.budget)}
                  />
                  <FormFeedback>{errors.budget}</FormFeedback>
                </FormGroup>
              )}
            </div>
            <div className="auth-actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup>
                <Row className="gap-xs">
                  <Col xs={6}>
                    <Button className="btn-block c-gray" color="default" onClick={goBack}>
                      <i className="btn-icon-back" />
                      Back
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      className="btn-block"
                      type="submit"
                      color="primary"
                      disabled={!(!!values.budget || !knowBudget)}
                      onClick={handleSubmit}
                    >
                      Next<i className="btn-icon-next"></i>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <p className="help-text">
              Need help? <NavLink to="#">Speak with a Sales Consultant.</NavLink>
            </p>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">
        <Promo {...promoReview} />
      </div>
    </div>
  );
};
