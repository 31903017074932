import React from 'react';
import { Row, Col } from 'reactstrap';
import styles from './Headline.module.scss';
import './Headline.scss';

interface Props {
  title: string;
  subTitle: string;
  navigation?: React.ReactElement;
  actions?: React.ReactElement;
}

export const Headline: React.FC<Props> = ({ title, subTitle, navigation, actions }) => {
  return (
    <div className={styles.headline}>
      <Row className="d-flex justify-content-between">
        <Col className="d-flex justify-content-between align-items-top">
          <div className={`d-xl-inline-block ${styles.headline__title}`}>
            <h1 className="page-title">{title}</h1>
            <em className={styles.info}>{subTitle}</em>
          </div>
          <div className="submenu-holder w-100 text-center">{navigation}</div>
          <div className={`ml-ch-10 text-right ${styles.headline__action}`}>{actions}</div>
        </Col>
      </Row>
    </div>
  );
};
