import { ProjectNode, ProjectsView } from 'types/project-types';
import { observable, computed } from 'mobx';
import { ToastStore } from 'store/toast-store';
import * as changeCase from 'change-case';
import { pretify } from 'utils/text-utils';

export class ProjectListEntity {
  @observable private _project: ProjectNode;
  @observable private toastStore: ToastStore;
  @observable private view: string | null;

  constructor(project: ProjectNode, toastStore: ToastStore, view: string | null) {
    this._project = project;
    this.toastStore = toastStore;
    this.view = view;
  }

  edit = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} editing call...`);
  };

  changeStatus = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} change status call...`);
  };

  duplicate = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} duplicate call...`);
  };

  archive = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} archive call...`);
  };

  delete = () => {
    this.toastStore.showSuccessMessage(`Project ${this.id} delete call...`);
  };

  @computed
  get id() {
    return this._project.id;
  }
  @computed
  get name() {
    return this._project.name;
  }
  @computed
  get agency() {
    return this._project.agencyName;
  }
  @computed
  get client(): string {
    return this._project.clientName;
  }
  @computed
  get status(): string {
    return changeCase.capitalCase(this._project.status);
  }

  @computed
  get used(): string {
    const v = this.view === ProjectsView.TIME ? this._project.usedTaasHours : this._project.usedUsd;
    return pretify(v, this.view);
  }
  @computed
  get budget(): string {
    const v = this.view === ProjectsView.TIME ? this._project.budgetTaasHours : this._project.budgetUsd;
    return pretify(v, this.view);
  }
  @computed
  get type(): string {
    return changeCase.capitalCase(this._project.type);
  }
  @computed
  get value(): number {
    const value = this.view === ProjectsView.TIME ? this._project.fulfilmentTaasHours : this._project.fulfilmentUsd;
    return Math.round(value * 100);
  }
  @computed
  get remaining(): string {
    const v = this.view === ProjectsView.TIME ? this._project.remainingTaasHours : this._project.remainingUsd;
    return pretify(v, this.view);
  }
  @computed
  get percentage(): number {
    const value = this.view === ProjectsView.TIME ? this._project.fulfilmentTaasHours : this._project.fulfilmentUsd;
    return Math.round((1 - value) * 100);
  }
  @computed
  get costs(): string {
    const v = this.view === ProjectsView.TIME ? this._project.costTaasHours : this._project.costUsd;
    return pretify(v, this.view);
  }
}
