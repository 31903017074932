import React from 'react';

import styles from './Progress.module.scss';

interface Props {
  value: number;
}

export const Progress: React.FC<Props> = ({ value }) => {
  const style = { width: `${value}%` };
  let addClass = "";

  if (value > 100) {
    addClass = "bg_red"
  };

  return (
    <div className={styles.progress_holder}>
      <div className={styles.progress_bar}>
        <div style={style} className={addClass} />
      </div>
    </div>
  );
};
