import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const TeamExpensesPage: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Helmet>
        <title>Expenses | Team Expenses</title>
      </Helmet>
      <div>{`Hello ${pathname}`}</div>
    </>
  );
};
