import React from 'react';
import { useAppStore } from 'store/app-store.hook';
import { useToasts } from 'react-toast-notifications';

export const useToastNotifications = () => {
  const { addToast } = useToasts();
  const { successMessage, errorMessage, clearSuccessMessage, clearErrorMessage } = useAppStore().toastStore;

  React.useEffect(() => {
    if (successMessage) {
      addToast(successMessage, { appearance: 'success', autoDismiss: true });
      clearSuccessMessage();
    }
  }, [addToast, clearSuccessMessage, successMessage]);

  React.useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, { appearance: 'error', autoDismiss: false });
      clearErrorMessage();
    }
  }, [addToast, clearErrorMessage, errorMessage]);
};
