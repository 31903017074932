import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { authRoutes } from './routes';

export const PrivateRoute: React.FC<RouteProps> = observer((props) => {
  const { authStore } = useAppStore();
  if (!authStore.me || !authStore.me.emailVerified) {
    return <Redirect to={authRoutes.LOGIN} />;
  }
  return <Route {...props} />;
});
