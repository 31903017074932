import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormGroup, Row } from 'reactstrap';
import { PromoReview } from '../../../types/project-request-types';
import { NavLink } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { Promo } from 'components/Promo';
import { ProjectRequestType } from 'types/gql-generated';
import { ChangeEventHandler } from 'react';

type FormValues = {
  type: ProjectRequestType;
};

const validationSchema = () =>
  Yup.object()
    .shape({
      type: Yup.mixed()
        .oneOf([
          ProjectRequestType.EXISTING_PROJECT,
          ProjectRequestType.LEARN_ABOUT,
          ProjectRequestType.NEW_PROJECT,
          ProjectRequestType.ONGOING_ASSISTANCE,
        ])
        .required(),
    })
    .required();

const initialValues = {
  type: ProjectRequestType.NEW_PROJECT,
};

interface StartProject3Props {
  error?: string;
  initial: { type?: ProjectRequestType | null };
  promoReview: PromoReview;
  onSend: (params: FormValues) => void;
  goBack: () => void;
}

export const StartProject3: React.FC<StartProject3Props> = ({ onSend, goBack, initial, error, promoReview }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: { type: initial.type || initialValues.type },
    onSubmit: onSend,
    validate: validate<FormValues>(validationSchema),
  });
  const { values, handleBlur, handleSubmit, setFieldValue } = formik;

  const onCheck = React.useCallback(
    (key: string): ChangeEventHandler<{ value: string }> => () => {
      setFieldValue('type', key, true);
    },
    [setFieldValue],
  );

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-progress-line">
          <div className="auth-progress-value step-3"></div>
        </div>
        <div className="auth-card card-wide">
          <Form onSubmit={handleSubmit}>
            <div className="auth-box">
              <div className="auth-headline">
                <strong className="auth-logo">
                  <Logo />
                </strong>
                <h1>What type of project is it?</h1>
              </div>
              <FormGroup className="checkbox-list">
                <CustomInput
                  type="radio"
                  id="radio1"
                  label="New idea or project"
                  name={ProjectRequestType.NEW_PROJECT}
                  required
                  onChange={onCheck(ProjectRequestType.NEW_PROJECT)}
                  onBlur={handleBlur}
                  checked={ProjectRequestType.NEW_PROJECT === values.type}
                />
                <CustomInput
                  type="radio"
                  id="radio2"
                  label="Existing project that needs more team resources"
                  name={ProjectRequestType.EXISTING_PROJECT}
                  required
                  onChange={onCheck(ProjectRequestType.EXISTING_PROJECT)}
                  onBlur={handleBlur}
                  checked={ProjectRequestType.EXISTING_PROJECT === values.type}
                />
                <CustomInput
                  type="radio"
                  id="radio3"
                  label="Ongoing assistance or consultation"
                  name={ProjectRequestType.ONGOING_ASSISTANCE}
                  required
                  onChange={onCheck(ProjectRequestType.ONGOING_ASSISTANCE)}
                  onBlur={handleBlur}
                  checked={ProjectRequestType.ONGOING_ASSISTANCE === values.type}
                />
                <CustomInput
                  type="radio"
                  id="radio4"
                  label="None of the above, I’m just looking to learn more about MindTrust"
                  name={ProjectRequestType.LEARN_ABOUT}
                  required
                  onChange={onCheck(ProjectRequestType.LEARN_ABOUT)}
                  onBlur={handleBlur}
                  checked={ProjectRequestType.LEARN_ABOUT === values.type}
                />
              </FormGroup>
            </div>
            <div className="auth-actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup>
                <Row className="gap-xs">
                  <Col xs={6}>
                    <Button className="btn-block c-gray" color="default" onClick={goBack}>
                      <i className="btn-icon-back" />
                      Back
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button className="btn-block" type="submit" color="primary" onClick={handleSubmit}>
                      Next<i className="btn-icon-next"></i>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <p className="help-text">
              Need help? <NavLink to="#">Speak with a Sales Consultant.</NavLink>
            </p>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">
        <Promo {...promoReview} />
      </div>
    </div>
  );
};
