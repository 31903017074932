import React from 'react';
import cn from 'classnames';
import { SelectOption } from 'types/select-types';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import { ViewAs } from '../ViewAs';
import './TableToolbar.scss';
import styles from './TableToolbar.module.scss';
import { DateGrouping } from 'types/gql-generated';

interface Props {
  className?: string;
  goPrev: () => void;
  goNext: () => void;
  goToday: () => void;
  onViewChange: (view: DateGrouping) => void;
  periodTitle: string;
  selectedView: DateGrouping;
  options: SelectOption<DateGrouping>[];
}

export const TableToolbar: React.FC<Props> = observer((props) => {
  const onViewChange = (selected: any) => props.onViewChange(selected.value);

  return (
    <div className={cn('d-flex', 'justify-content-between', styles.navigation, props.className)}>
      <div className={styles.btns_set_left}>
        <Button className={styles.btn_today} color="default" onClick={props.goToday}>
          Today
        </Button>
        <Button className={`icon-prev ${styles.button}`} onClick={props.goPrev}>
          {' < '}
        </Button>
        <Button className={`icon-next ${styles.button}`} onClick={props.goNext}>
          {' > '}
        </Button>
        <strong className={`ml-12 ${styles.navigation_title}`}>
          <span>{props.periodTitle}</span>
        </strong>
      </div>
      {/* {props.selectedView === DateGrouping.CUSTOM && (
        <div className={styles.btns_set_left}>
          <div className={styles.date_picker}>
            <span className={styles.main_label}>Custom timeframe</span>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={dates.startDate} // Date or null
              endDate={dates.endDate}
              onDatesChange={setDates}
              focusedInput={focusInput}
              onFocusChange={setFocusInput}
              isOutsideRange={falseFunc}
            />
            <Button className="ml-12" color="primary">
              Update
            </Button>
          </div>
        </div>
      )} */}
      <div className={`ml-ch-6 ${styles.btns_set_right}`}>
        <Select
          className="mr-12 select-container w-130"
          classNamePrefix="select"
          options={props.options}
          placeholder="HOLDER"
          isMulti={false}
          value={props.options.find(({ value }) => value === props.selectedView)}
          onChange={onViewChange}
          searchable={false}
        />
        <ViewAs />
      </div>
    </div>
  );
});
