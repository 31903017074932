import { computed, action, observable, reaction } from 'mobx';
import { ProjectListEntity } from '../entities/project-list-entitiy';
import { ToastStore } from 'store/toast-store';
import { RouterStore } from 'mobx-react-router';
import { ProjectNode, ProjectsView } from 'types/project-types';
import { ProjectsService } from 'store/services/projects-service';
import { Predicate, FilterInput, removeFalsy, Paging } from 'types/search-filter-types';
import queryString from 'query-string';
import { SortInput, SortDir } from 'types/gql-generated';

export class ProjectsStore {
  private toastStore: ToastStore;
  private projectsService: ProjectsService;
  @observable private _search: Predicate[] = [];
  @observable private _filters: Predicate[] = [];
  @observable private _sorting: SortInput[] = [];
  @observable private _projectNodes: ProjectNode[] = [];
  @observable private routerStore: RouterStore;
  @observable total = 0;
  @observable isLoading: boolean = false;

  constructor(projectsService: ProjectsService, routerStore: RouterStore, toastStore: ToastStore) {
    this.projectsService = projectsService;
    this.routerStore = routerStore;
    this.toastStore = toastStore;

    reaction(
      () => this.input,
      () => this.loadProjects(this.paging),
    );
  }

  @computed
  private get paging() {
    const { search } = this.routerStore.location;
    const { offset = 0, limit = 10 } = queryString.parse(search);
    return { offset: Number(offset), limit: Number(limit) };
  }

  @computed
  private get input() {
    return {
      sort: this._sorting,
      query: {
        logical: 'and',
        predicates: [
          {
            logical: 'or',
            predicates: this._search,
          },
          {
            logical: 'or',
            predicates: this._filters,
          },
        ],
      },
    };
  }

  @computed
  get projects() {
    const { search } = this.routerStore.location;
    const { view } = queryString.parse(search);
    return this._projectNodes.map((p) => {
      return new ProjectListEntity(p, this.toastStore, view as string);
    });
  }

  @action
  loadProjects = async (paging: Paging) => {
    this.isLoading = true;
    try {
      const { nodes, total } = await this.projectsService.loadProjects({ ...this.input, ...paging });
      this.total = total;
      this._projectNodes = nodes;
    } catch (error) {
      this.toastStore.showErrorMessage(error.message);
    }
    this.isLoading = false;
  };

  @action
  searchProjects = (input: string) => {
    this._search = !!input
      ? ['name', 'agencyName', 'clientName'].map((field) => ({
          field,
          operator: 'ilike',
          value: `${input}`,
        }))
      : [];
  };

  @action
  applyFilters = (filters: FilterInput) => {
    const f = removeFalsy(filters);
    this._filters = Object.keys(f).flatMap((field) =>
      Object.keys(f[field]).map((value) => ({
        field,
        operator: '=',
        value,
      })),
    );
  };

  @action
  sort = (field: string, dir: 'desc' | 'asc') => {
    const { search } = this.routerStore.location;
    const { view } = queryString.parse(search);
    this._sorting = [
      {
        field: columnMapper(field, view as string),
        dir: dir === 'desc' ? SortDir.DESC : SortDir.ASC,
      },
    ];
  };
}

const columnMapper = (field: string, view: string) => {
  if (field === 'used') return view === ProjectsView.TIME ? 'usedTaasHours' : 'usedUsd';
  if (field === 'budget') return view === ProjectsView.TIME ? 'budgetTaasHours' : 'budgetUsd';
  if (field === 'remaining') return view === ProjectsView.TIME ? 'remainingTaasHours' : 'remainingUsd';
  if (field === 'costs') return view === ProjectsView.TIME ? 'costTaasHours' : 'costUsd';
  return field;
};
