import { DateGrouping, Role } from './gql-generated';
import { MT_ROLES, SALES_ROLES, CLIENT_ROLES, AGENCY_ROLES } from './auth-types';

export const getSelectOption = <T>(value: T, options: SelectOption<T>[]): SelectOption<T> | undefined =>
  options.find((o) => o.value === value);

export interface SelectOption<T> {
  label: string;
  value: T;
}

export const PERIOD_SELECT_OPTIONS: SelectOption<DateGrouping>[] = [
  { label: 'Month', value: DateGrouping.MONTH },
  { label: 'Week', value: DateGrouping.WORK_WEEK },
  { label: 'Day', value: DateGrouping.DAY },
];

const mapRolesToOption = (roles: Role[]) =>
  roles.map((r) => ({
    label: r,
    value: r,
  }));

export const USER_ROLES_SELECT_OPTIONS: SelectOption<Role>[] = [
  ...mapRolesToOption(MT_ROLES),
  ...mapRolesToOption(SALES_ROLES),
  ...mapRolesToOption(AGENCY_ROLES),
  ...mapRolesToOption(CLIENT_ROLES),
];
