import { UserTokens } from 'types/auth-types';
import { ProjectRequestInput } from 'types/gql-generated';

enum StorageKeys {
  user_tokens = 'user_tokens',
  user_info = 'user_info',
  project_request = 'project_request',
}

export const saveTokenToLocalStorage = (userTokens: UserTokens): UserTokens => {
  localStorage.setItem(StorageKeys.user_tokens, JSON.stringify(userTokens));
  return userTokens;
};

export const readTokenToLocalStorage = (): UserTokens | null => {
  const tokensStr = localStorage.getItem(StorageKeys.user_tokens);
  return tokensStr && JSON.parse(tokensStr);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(StorageKeys.user_tokens);
  localStorage.removeItem(StorageKeys.user_info);
};

type ProjectRequestStorage = { projectRequest: ProjectRequestInput; currentStep: number };
export const saveProjectRequest = (projectRequest: ProjectRequestStorage): ProjectRequestStorage => {
  localStorage.setItem(StorageKeys.project_request, JSON.stringify(projectRequest));
  return projectRequest;
};

export const readProjectRequest = (): ProjectRequestStorage | null => {
  const projectRequest = localStorage.getItem(StorageKeys.project_request);
  return projectRequest && JSON.parse(projectRequest);
};

export const cleanProjectRequest = () => {
  localStorage.removeItem(StorageKeys.project_request);
};
