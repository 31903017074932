import { Role } from 'types/gql-generated';

export const myTimesheet = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
];

export const teamTimesheets = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
];

export const mySchedule = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
];

export const teamSchedule = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
];

export const companyCalendar = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
  Role.MT_SALES_REP,
];

export const myCalendar = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
];

export const myTimeOff = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
  Role.MT_TEAM_MEMBER,
  Role.MT_SALES_ADMIN,
  Role.MT_SALES_MGR,
];

export const teamCalendars = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
];

export const teamTimeOff = [
  Role.MT_SUPER_ADMIN_OWNER,
  Role.MT_ADMIN,
  Role.MT_FINANCE,
  Role.MT_RESOURCE_OPS,
  Role.MT_TEAM_PM,
];
