import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject4 } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest4Page = observer(() => {
  const { requestProjectStore } = useAppStore();
  const { projectRequest } = requestProjectStore;

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject4
        initial={{
          budget:
            projectRequest.budgetFromUsd || projectRequest.budgetToUsd
              ? `${projectRequest.budgetFromUsd}_${projectRequest.budgetToUsd}`
              : null,
        }}
        onSend={requestProjectStore.fourthStep}
        goBack={requestProjectStore.goBack}
        promoReview={requestProjectStore.getPromoReview()} 
      />
    </>
  );
});
