import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import Select from 'react-select/async';
import { ActionMeta, ValueType } from 'react-select';
import { Promo } from 'components/Promo';
import { requestProjectRoutes } from 'routing';
import { SelectOption } from 'types/select-types';
import { PromoReview, StartStartProject2ResourceBased } from 'types/project-request-types';
import makeAnimated from 'react-select/animated';
import { observer } from 'mobx-react-lite';
import debounce from 'debounce-promise';

const animatedComponents = makeAnimated<SelectOption<string>>();
type FormValues = StartStartProject2ResourceBased;

const validationSchema = () => Yup.object().shape({}).required();

const initialValues = {
  talents: [],
};

interface Props {
  error?: string;
  initial: FormValues;
  loadOptions: (input?: string) => Promise<SelectOption<string>[]>;
  skillsOptions: SelectOption<string>[];
  promoReview: PromoReview;
  onSend: (params: FormValues) => void;
  goBack: () => void;
}

export const StartProject2ResourceBased: React.FC<Props> = observer(
  ({ initial, skillsOptions, onSend, goBack, loadOptions, error, promoReview }) => {
    const { search } = useLocation();
    const formik = useFormik<FormValues>({
      enableReinitialize: true,
      initialValues: {
        ...initialValues,
        ...initial,
      },
      onSubmit: onSend,
      validate: validate(validationSchema),
    });
    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = formik;

    const onSelect = React.useCallback(
      (_: ValueType<SelectOption<string>>, action: ActionMeta<SelectOption<string>>) => {
        const { option, removedValue } = action;
        if (!option && !removedValue) return setFieldValue('skills', [], true);
        if (!!removedValue) {
          const skills = (values.skills || []).filter((t) => removedValue?.value !== t);
          return setFieldValue('skills', skills, true);
        }
        const value = option?.value;
        const isExist = !!(values.skills || []).find((s) => value === s);
        const skills = isExist ? (values.skills || []).filter((s) => value !== s) : [...(values.skills || []), value];
        setFieldValue('skills', skills, true);
      },
      [values, setFieldValue],
    );

    const onCheck = React.useCallback(
      (key: string): ChangeEventHandler<{ value: string }> => () => {
        const isExist = !!(values.skills || []).find((t) => key === t);
        const skills = isExist ? (values.skills || []).filter((t) => key !== t) : [...(values.skills || []), key];
        setFieldValue('skills', skills, true);
      },
      [values, setFieldValue],
    );

    const loadSkillOptions = debounce(loadOptions, 500, { leading: true });

    return (
      <div className="auth-container d-flex h-100">
        <div className="auth-col auth-left">
          <div className="auth-progress-line">
            <div className="auth-progress-value step-2"></div>
          </div>
          <div className="auth-card card-wide">
            <Form onSubmit={handleSubmit}>
              <div className="auth-box">
                <div className="auth-headline">
                  <strong className="auth-logo">
                    <Logo />
                  </strong>
                  <h1>What resource do you need?</h1>
                  <p>
                    I’d like to look for a{' '}
                    <Link to={`${requestProjectRoutes.request(2)}${search}`}>project talents</Link> instead.
                  </p>
                </div>
                <FormGroup
                  className={classNames(
                    'form-group-addon',
                    { 'input-active': values.skills },
                    { 'input-invalid': touched.skills && !!errors.skills },
                    { 'input-touched': touched.skills },
                    { 'input-valid': touched.skills && !errors.skills },
                  )}
                >
                  <Select
                    components={animatedComponents}
                    className="w-100 select-container select-search select-search--multi"
                    classNamePrefix="select"
                    placeholder="Search for a role"
                    onChange={onSelect}
                    defaultOptions={skillsOptions}
                    value={(values.skills || []).map((s) => ({ label: s, value: s }))}
                    loadOptions={loadSkillOptions}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                  <FormFeedback>{errors.skills}</FormFeedback>
                </FormGroup>
                <FormGroup className="checkbox-list">
                  <span className="label">Popular roles</span>
                  {skillsOptions.slice(0, 6).map((option) => (
                    <CustomInput
                      type="checkbox"
                      key={`${option.value}`}
                      id={`${option.value}`}
                      label={`${option.label}`}
                      checked={!!(values.skills || []).find((t) => t === option.value)}
                      onChange={onCheck(option.value)}
                      onBlur={handleBlur}
                    />
                  ))}
                </FormGroup>
              </div>
              <div className="auth-actions">
                {error && <div className="text-center text-danger mb-1">{error}</div>}
                <FormGroup>
                  <Row className="gap-xs">
                    <Col xs={6}>
                      <Button className="btn-block c-gray" color="default" onClick={goBack}>
                        <i className="btn-icon-back" />
                        Back
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button
                        className="btn-block"
                        type="submit"
                        color="primary"
                        disabled={!(values.skills || []).length}
                        onClick={handleSubmit}
                      >
                        Next<i className="btn-icon-next"></i>
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <p className="help-text">
                Need help? <Link to="#">Speak with a Sales Consultant.</Link>
              </p>
            </Form>
          </div>
        </div>
        <div className="auth-col auth-right w-600 d-none d-lg-block">
          <Promo {...promoReview} />
        </div>
      </div>
    );
  },
);
