import React from 'react';

import { SuccessIcon } from '../SvgIcons/Success';

export const SuccessPopup = () => {
  return (
    <div className="popup-success d-flex align-items-center justify-content-center">
      <div className="popup-frame">
        <SuccessIcon />
        <p>Success!</p>
      </div>
    </div>
  );
};
