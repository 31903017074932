import { SubMenu } from 'components/SubMenu';
import React from 'react';
import { projectsRoutes } from 'routing';

interface Props {
  id: string;
}

export const ProjectNavigtaion: React.FC<Props> = ({ id }) => {
  return (
    <SubMenu
      items={[
        {
          label: 'Overview',
          href: projectsRoutes.PROJECT_OVERVIEW(id),
        },
        {
          label: 'Details',
          href: projectsRoutes.PROJECT_DETAILS(id),
        },
        {
          label: 'Reports',
          href: projectsRoutes.PROJECT_REPORTS(id),
        },
        {
          label: 'Invoices',
          href: projectsRoutes.PROJECT_INVOICES(id),
        },
        {
          label: 'Quicklinks',
          href: projectsRoutes.PROJECT_QUICKLINKS(id),
        },
      ]}
    />
  );
};
