import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { timeRoutes } from 'routing';
import { MyTimesheetPage } from './MyTimeSheetPage';
import { TeamTimesheetPage } from './TeamTimesheetPage';
import { MySchedulePage } from './MySchedulePage';
import { TeamSchedulePage } from './TeamSchedulePage';
import { CompanyCalendarPage } from './CompanyCalendarPage';
import { MyCalendarPage } from './MyCalendarPage';
import { MyTimeOffPage } from './MyTimeOffPage';
import { TeamCalendarsPage } from './TeamCalendarsPage';
import { TeamTimeOffPage } from './TeamTimeOffPage';
import { useAppStore } from 'store/app-store.hook';

export const TimeRoutes: React.FC = observer(() => {
  const { time } = useAppStore().permissionStore;
  return (
    <Switch>
      {time.myTimesheet && <Route exact path={timeRoutes.MY_TIMESHEET} component={MyTimesheetPage} />}
      {time.teamTimesheet && <Route exact path={timeRoutes.TEAM_TIMESHEET} component={TeamTimesheetPage} />}
      {time.mySchedule && <Route exact path={timeRoutes.MY_SCHEDULE} component={MySchedulePage} />}
      {time.teamSchedule && <Route exact path={timeRoutes.TEAM_SCHEDULE} component={TeamSchedulePage} />}
      {time.companyCalendar && <Route exact path={timeRoutes.COMPANY_CALENDAR} component={CompanyCalendarPage} />}
      {time.myCalendar && <Route exact path={timeRoutes.MY_CALENDAR} component={MyCalendarPage} />}
      {time.myTimeOff && <Route exact path={timeRoutes.MY_TIME_OFF} component={MyTimeOffPage} />}
      {time.teamCalendars && <Route exact path={timeRoutes.TEAM_CALENDARS} component={TeamCalendarsPage} />}
      {time.teamTimeOff && <Route exact path={timeRoutes.TEAM_TIME_OFF} component={TeamTimeOffPage} />}
    </Switch>
  );
});
