import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const DepartmentsPage = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>Global Settings | Departments</title>
      </Helmet>
      <div>{`Hello ${location.pathname}`}</div>
    </>
  );
};
