import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Card, CardBody, CustomInput, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { validators, validate } from '../../utils/form-utils';
import { EmailIcon } from '../SvgIcons/Email';
import { Role } from 'types/gql-generated';
import { InviteParams, UserRoles } from 'types/referral-types';
import { FormikSelect } from 'components/FormikSelect';
import { USER_ROLES_SELECT_OPTIONS, getSelectOption } from 'types/select-types';

interface FormValues {
  email: string;
  roleName: Role;
  twoFactorEnabled: boolean;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      email: validators.email,
      roleName: Yup.mixed().oneOf(UserRoles),
      twoFactorEnabled: Yup.boolean().required(),
    })
    .required();

const initialValues = {
  email: '',
  roleName: Role.MT_ADMIN,
  twoFactorEnabled: false,
};

interface InvintationFormProps {
  onInvite: (params: InviteParams) => void;
}

export const InvintationForm: React.FC<InvintationFormProps> = ({ onInvite }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onInvite,
    validate: validate(validationSchema),
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isValid,
  } = formik;
  return (
    <Card className="w-23">
      <CardBody>
        <div className="card-headline">
          <h1>Invite</h1>
          <p>Fill out the following information to invite user.</p>
        </div>
        <Form onSubmit={handleSubmit} noValidate>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.email },
              { 'input-invalid': touched.email && !!errors.email },
              { 'input-touched': touched.email },
              { 'input-valid': touched.email && !errors.email },
            )}
          >
            <label htmlFor="email">
              <EmailIcon />
            </label>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              valid={touched.email && !errors.email}
              invalid={touched.email && !!errors.email}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.roleName },
              { 'input-invalid': touched.roleName && !!errors.roleName },
              { 'input-touched': touched.roleName },
              { 'input-valid': touched.roleName && !errors.roleName },
            )}
          >
            <FormikSelect
              className={classNames('', {
                'is-invalid': !!values.roleName && touched.roleName && !!errors[`roleName`],
                'is-valid': !!values.roleName && touched.roleName && !errors[`roleName`],
              })}
              placeholder="Select roleName"
              name="roleName"
              value={getSelectOption(values.roleName, USER_ROLES_SELECT_OPTIONS) || USER_ROLES_SELECT_OPTIONS[0]}
              options={USER_ROLES_SELECT_OPTIONS}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <FormFeedback>{errors.roleName}</FormFeedback>
          </FormGroup>
          <FormGroup className="d-flex pt-6">
            <CustomInput
              type="checkbox"
              id="twoFactorEnabled"
              label="Enabled Two Factor by default"
              required
              valid={touched.twoFactorEnabled && !errors.twoFactorEnabled}
              invalid={touched.twoFactorEnabled && !!errors.twoFactorEnabled}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <FormFeedback>{errors.twoFactorEnabled}</FormFeedback>
            </CustomInput>
          </FormGroup>
          <FormGroup className="actions">
            <Button type="submit" color="primary" className="btn-block" disabled={!isValid} onClick={handleSubmit}>
              Send invintation
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};
