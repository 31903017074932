import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { referralsRoutes } from 'routing';
import { ReferredUsersPage } from './ReferredUsersPage';
import { CommissionsPage } from './CommissionsPage';
import { PaymentOptionsPage } from './PaymentOptionsPage';
import { ExpectedCommissionsPage } from './ExpectedCommissionsPage';
import { PromoSalesCollateralPage } from './PromoSalesCollateralPage';

export const ReferralsRoutes: React.FC = observer(() => {
  const { referrals } = useAppStore().permissionStore;
  return (
    <Switch>
      {referrals.referredUsers && <Route exact path={referralsRoutes.REFERRED_USERS} component={ReferredUsersPage} />}
      {referrals.commissions && <Route exact path={referralsRoutes.COMMISSIONS} component={CommissionsPage} />}
      {referrals.paymentOptions && (
        <Route exact path={referralsRoutes.PAYMENT_OPTIONS} component={PaymentOptionsPage} />
      )}
      {referrals.expectedCommissions && (
        <Route exact path={referralsRoutes.EXPECTED_COMMISSIONS} component={ExpectedCommissionsPage} />
      )}
      {referrals.promoSalesCollateral && (
        <Route exact path={referralsRoutes.PROMO_SALES_COLLATERAL} component={PromoSalesCollateralPage} />
      )}
    </Switch>
  );
});
