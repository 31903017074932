import React from 'react';
import styles from './Loader.module.scss';
import './Loader.scss';

export const Loader: React.FC = () => (
  <div className={`${styles.loader}`}>
    <div className={`fadeIn ${styles.lds_ripple}`}>
      <div></div>
      <div></div>
    </div>
  </div>
);
