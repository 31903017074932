import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ProjectsPage } from './ProjectsPage';
import { projectsRoutes } from '../../routing';
import { useAppStore } from 'store/app-store.hook';
import { ProjectOverviewPage } from './ProjectOverviewPage';
import { ProposalsPage } from './ProposalsPage';
import { PendingPage } from './PendingPage';
import { ProjectQuicklinksPage } from './ProjectQuicklinksPage';
import { ProjectDetailsPage } from './ProjectDetailsPage';
import { ProjectReportsPage } from './ProjectReportsPage';
import { ProjectInvoicesPage } from './ProjectInvoicesPage';

export const ProductsRoutes: React.FC = observer(() => {
  const { projects } = useAppStore().permissionStore;
  return (
    <Switch>
      {projects.projects.overview && <Route exact path={projectsRoutes.PROJECTS_LIST} component={ProjectsPage} />}
      {projects.projects.overview && (
        <Route exact path={projectsRoutes.PROJECT_OVERVIEW()} component={ProjectOverviewPage} />
      )}
      {projects.projects.details && (
        <Route exact path={projectsRoutes.PROJECT_DETAILS()} component={ProjectDetailsPage} />
      )}
      {projects.projects.reports && (
        <Route exact path={projectsRoutes.PROJECT_REPORTS()} component={ProjectReportsPage} />
      )}
      {projects.projects.invoices && (
        <Route exact path={projectsRoutes.PROJECT_INVOICES()} component={ProjectInvoicesPage} />
      )}
      {projects.projects.quicklinks && (
        <Route exact path={projectsRoutes.PROJECT_QUICKLINKS()} component={ProjectQuicklinksPage} />
      )}
      {projects.pending && <Route exact path={projectsRoutes.PENDING} component={ProposalsPage} />}
      {projects.proposals && <Route exact path={projectsRoutes.PROPOSALS} component={PendingPage} />}
    </Switch>
  );
});
