export const links = [
  {
    id: 1,
    title: 'Clickable Prototype',
    logo: '/assets/svg/logo-figma.svg',
  },
  {
    id: 2,
    title: 'Clearbuild GDrive',
    logo: '/assets/svg/logo-google-drive.svg',
  },
  {
    id: 3,
    title: 'Clearbuild Spec',
    logo: '/assets/svg/logo-google-docs.svg',
  },
  {
    id: 4,
    title: 'Clearbuild - Jira Project',
    logo: '/assets/svg/logo-jira.svg',
  },
  {
    id: 5,
    title: 'Content File Structure',
    logo: '/assets/svg/logo-google-docs.svg',
  },
  {
    id: 6,
    title: 'User Roles User Stories',
    logo: '/assets/svg/logo-google-docs.svg',
  },
  {
    id: 7,
    title: 'Clearbuild - Hubspot',
    logo: '/assets/svg/logo-hubspot.svg',
  },
  {
    id: 8,
    title: 'Shared Clearbuild Drive',
    logo: '/assets/svg/logo-google-drive.svg',
  },
  {
    id: 9,
    title: 'Quickbooks CB Project',
    logo: '/assets/svg/logo-quickbooks.svg',
  },
  {
    id: 10,
    title: 'Raw design file',
    logo: '/assets/svg/logo-figma.svg',
  },
];
