import React from 'react';

import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';

export const CheckEmail = () => {
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>Check your inbox</h1>
            <p>We just sent you an email with a link. Please click on that link to verify your acount.</p>

          </div>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block">

      </div>
    </div>
  );
};
