import React from 'react';
import { Button } from 'reactstrap';
import styles from './Menu.module.scss';
import { observer } from 'mobx-react-lite';

interface Props {
  edit: () => void;
  changeStatus: () => void;
  duplicate: () => void;
  archive: () => void;
  delete: () => void;
}

export const Menu: React.FC<Props> = observer((props) => {
  return (
    <div className={styles.menu}>
      <Button color="custom" className={styles.menu_link}>Menu</Button>
      <div className={styles.menu_drop}>
        <ul>
          <li>
            <Button color="custom" onClick={props.edit} className={styles.menu_sublink}>Edit</Button>
          </li>
          <li>
            <Button color="custom" onClick={props.changeStatus} className={styles.menu_sublink}>Change Status</Button>
          </li>
          <li>
            <Button color="custom" onClick={props.duplicate} className={styles.menu_sublink}>Duplicate</Button>
          </li>
          <li>
            <Button color="custom" onClick={props.archive} className={styles.menu_sublink}>Archive</Button>
          </li>
          <li>
            <Button color="custom" onClick={props.delete} className={`${styles.menu_sublink} ${styles.link_delete}`}>
              Delete
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
});
