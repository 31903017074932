import React from 'react';

import { Button } from 'reactstrap';
import cn from 'classnames';
import { GroupTable } from '../GroupTable';
import './DateTable.scss';
import styles from './DateTable.module.scss';
import { useTimeline } from './use-timeline';
import {
  getTimeframe,
  getDayTimeframe,
  getViewFromUrl,
  getRangeFromUrl,
  curentYearRange,
} from '../../utils/month-generator';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../store/app-store.hook';

import { useLocation } from 'react-router-dom';
import { AddCreditCategoryPopup } from 'components/AddCreditCategoryPopup';
import { DateGrouping } from 'types/gql-generated';

interface Props {}

export const DateTable: React.FC<Props> = observer(() => {
  const { groups } = useAppStore();
  const { search } = useLocation();
  const [view] = React.useState<DateGrouping>(getViewFromUrl(search));
  const [selectedIndex, setSelectedIndex] = React.useState<null | number>(null);
  const [periodRange] = useTimeline(getRangeFromUrl(search, curentYearRange), view);

  const onColumnClick = React.useCallback((index: number | null) => {
    setSelectedIndex(index);
  }, []);

  const getSecondaryHeader = () => {
    switch (view) {
      case DateGrouping.WORK_WEEK:
        return getTimeframe(periodRange).flatMap((frame) => [
          frame.month,
          ...Array(frame.weeks.length).fill('').slice(1),
        ]);
      case DateGrouping.DAY:
        return getDayTimeframe(periodRange).flatMap((frame) => [
          frame.week,
          ...Array(frame.days.length).fill('').slice(1),
        ]);
    }
    return [];
  };

  const getMainHeader = () => {
    switch (view) {
      case DateGrouping.MONTH:
        return getTimeframe(periodRange).map((frame) => frame.month);
      case DateGrouping.WORK_WEEK:
        return getTimeframe(periodRange).flatMap((frame) => frame.weeks.map((week) => week));
      case DateGrouping.DAY:
        return getDayTimeframe(periodRange).flatMap((frame) => frame.days.map((days) => days));
    }
  };

  const getTableData = () => {
    switch (view) {
      case DateGrouping.MONTH:
        return getTimeframe(periodRange).map((frame) => frame.month);
      case DateGrouping.WORK_WEEK:
        return getTimeframe(periodRange).flatMap((frame) => frame.weeks.map((week) => week));
      case DateGrouping.DAY:
        return getDayTimeframe(periodRange).flatMap((frame) => frame.days.map((days) => days));
    }
  };

  const mainHeader = getMainHeader();
  const secondaryHeader = getSecondaryHeader();
  const tableData = getTableData();

  return (
    <div className={styles.table_wrapper}>
      <div className={styles.date_table_holder}>
        <table className={styles.date_table}>
          <thead>
            {!!secondaryHeader.length && (
              <tr className={styles.header_top}>
                <th className={styles.extended} />
                {secondaryHeader.map((item, i) => (
                  <th
                    key={`${item}_${i}`}
                    className={cn({
                      [styles.selected]: i === selectedIndex,
                      [styles.item_title]: item,
                      [styles.borderless]: !item,
                    })}
                  >
                    {item}
                  </th>
                ))}
              </tr>
            )}
            {!!mainHeader.length && (
              <tr className={styles.header}>
                {<th className={styles.extended} />}
                {mainHeader.map((week, i) => (
                  <th
                    className={cn({
                      [styles.selected]: i === selectedIndex,
                    })}
                    key={`th_${i}`}
                  >
                    <div>{week}</div>
                  </th>
                ))}
              </tr>
            )}
          </thead>
        </table>
      </div>
      {groups.map((g) => (
        <GroupTable
          {...g}
          key={g.name}
          timeframe={tableData}
          onClick={onColumnClick}
          selectedIndex={selectedIndex}
          expand={!!g.categories.length}
        />
      ))}
      <Button color="inline">
        <i className="icon-add"></i>Add Credit Category
      </Button>
      <Button color="inline">
        <i className="icon-add"></i>Add Talent
      </Button>
      <Button color="inline">
        <i className="icon-add"></i>Add Expense Category
      </Button>
      <AddCreditCategoryPopup onSend={() => undefined} />
    </div>
  );
});
