import React from 'react';
import classNames from 'classnames';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import { validate, validators } from '../../../utils/form-utils';
import { authRoutes } from '../../../routing/routes';
import { ForgotPasswordRequest } from '../../../types/auth-types';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';

interface FormValues {
  email: string;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      email: validators.email,
    })
    .required();

const initialValues = {
  email: '',
};

interface ForgotPasswordFormProps {
  error?: string;
  onSend: (params: ForgotPasswordRequest) => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSend, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onSend,
    validate: validate<FormValues>(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>Reset your password</h1>
            <p>Give us your email, and we’ll send you a link to reset your password.</p>
          </div>
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.email },
                { 'input-invalid': touched.email && !!errors.email },
                { 'input-touched': touched.email },
                { 'input-valid': touched.email && !errors.email },
              )}
            >
              <label htmlFor="email">
                Email
              </label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                autoComplete="email"
                valid={touched.email && !errors.email}
                invalid={touched.email && !!errors.email}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            {error && <div className="text-center text-danger mb-1">{error}</div>}
            <FormGroup className="actions">
              <Button type="button" color="primary" className="btn-block" disabled={!isValid} onClick={handleSubmit}>
                Send reset instructions
              </Button>
            </FormGroup>
            <div className="d-flex align-items-center justify-content-center">
              <span className="mr-1">Remember your password?</span>
              <NavLink className="text-center" to={authRoutes.LOGIN}>
                Login
              </NavLink>
            </div>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block">

      </div>
    </div>
  );
};
