import React, { Suspense } from 'react';
import { Container } from 'reactstrap';
import { AppSidebarToggler } from '@coreui/react';
import { AppFooter } from '@coreui/react';
import { Footer } from './Footer';

import './Layout.scss';
import { Loader } from '../../Loader';
import { observer } from 'mobx-react-lite';
import { LeftNavigation } from 'components/LeftNavigation';

export const Layout: React.FC = observer(({ children, ...other }) => {
  return (
    <div className="app">
      <div className="app-body">
        <Suspense fallback={<div className="fadeIn loader-fixed"><Loader /></div>}>
          <LeftNavigation />
        </Suspense>
        <div className="main d-flex flex-column">
          <main className="main-container">
            <Container fluid>
              <div className="d-lg-none mt-3 toggler-holder mlr-sm plr-sm">
                <AppSidebarToggler display="sm" mobile />
              </div>
              {children}
            </Container>
          </main>
          <AppFooter className="mt-2">
            <Footer />
          </AppFooter>
        </div>
      </div>
    </div>
  );
});
