import * as queryString from 'query-string';
import { REDIRECT_URI, CLIENT_ID } from 'config';

const stringifiedParams = queryString.stringify({
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  scope: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'].join(
    ' ',
  ), // space seperated string
  response_type: 'code',
  access_type: 'offline',
  prompt: 'consent',
});

export const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;

