import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Form, FormFeedback, FormGroup } from 'reactstrap';
import { validators, validate } from '../../../utils/form-utils';
import { PhoneRegisterRequest } from '../../../types/auth-types';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import ReactPhoneInput from 'react-phone-input-2';
import './RegisterPhoneForm.scss';

interface FormValues {
  phone: string;
  countryCode: number;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      countryCode: validators.countryCode,
      phone: validators.phoneNumber,
    })
    .required();

const initialValues = {
  phone: '',
  countryCode: 1,
};

interface RegisterPhoneFormProps {
  onRegister: (params: PhoneRegisterRequest) => void;
  error?: string;
}

export const RegisterPhoneForm: React.FC<RegisterPhoneFormProps> = ({ onRegister, error }) => {
  const onSubmit = (values: FormValues) => {
    onRegister({ countryCode: values.countryCode, phone: values.phone });
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onSubmit,
    validate: validate(validationSchema, (values) => ({ ...values })),
  });

  const { values, errors, touched, handleSubmit, setFieldTouched, setFieldValue, isValid } = formik;
  const onPhoneChange = (number: string, { dialCode }: { dialCode: number }) => {
    setFieldTouched('countryCode', true);
    setFieldValue('countryCode', Number(dialCode), false);
    setFieldTouched('phone', true);
    setFieldValue('phone', number, true);
  };

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>What is your number?</h1>
            <p>Please provide your mobile number so we can text you a code. Data and messaging rates may apply.</p>
          </div>
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <FormGroup>
              <label>Phone number</label>
              <ReactPhoneInput
                containerClass={classNames(
                  'react-tel-input form-control',
                  { 'is-active': values.phone },
                  { 'is-invalid': touched.phone && !!errors.phone },
                  { 'is-touched': touched.phone },
                  { 'is-valid': touched.phone && !errors.phone },
                )}
                inputExtraProps={{
                  name: 'phone',
                  required: true,
                }}
                defaultCountry="us"
                placeholder="(000) 000-0000"
                value={values.phone}
                onChange={onPhoneChange}
                enableSearch
              />
              <FormFeedback className="order-3">{errors.phone}</FormFeedback>
            </FormGroup>
            {error && <div className="text-center text-danger mb-1">{error}</div>}
            <FormGroup className="actions">
              <Button type="submit" color="primary" className="btn-block" disabled={!isValid} onClick={handleSubmit}>
                Send me a code
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block"></div>
    </div>
  );
};
