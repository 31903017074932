import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, CustomInput, Form, FormFeedback, FormGroup, Input, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { validators, validate } from '../../../utils/form-utils';
import { RegisterRequest } from '../../../types/auth-types';
import { authRoutes } from '../../../routing';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { GoogleIcon } from '../../../components/SvgIcons/Google';
import { googleLoginUrl } from 'utils/google-login';

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  accept: string[];
}

const validationSchema = () =>
  Yup.object()
    .shape({
      email: validators.email,
      firstName: validators.firstName,
      lastName: validators.lastName,
      password: validators.password,
      accept: validators.accept,
    })
    .required();

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  accept: [],
};

interface RegistrationFormProps {
  error?: string;
  email?: string;
  onRegister: (params: RegisterRequest) => void;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({ onRegister, email = '', error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      email,
    },
    onSubmit: ({ accept, ...values }) => onRegister(values),
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>Register for an Account</h1>
            <p>
              Already have an account?{' '}
              <Link className="text-center" to={authRoutes.LOGIN}>
                Sign In
              </Link>
            </p>
          </div>
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.firstName },
                { 'input-invalid': touched.firstName && !!errors.firstName },
                { 'input-touched': touched.firstName },
                { 'input-valid': touched.firstName && !errors.firstName },
              )}
            >
              <label htmlFor="firstName">First Name</label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First Name"
                valid={touched.firstName && !errors.firstName}
                invalid={touched.firstName && !!errors.firstName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </FormGroup>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.lastName },
                { 'input-invalid': touched.lastName && !!errors.lastName },
                { 'input-touched': touched.lastName },
                { 'input-valid': touched.lastName && !errors.lastName },
              )}
            >
              <label htmlFor="lastName">Last Name</label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                valid={touched.lastName && !errors.lastName}
                invalid={touched.lastName && !!errors.lastName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </FormGroup>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.email },
                { 'input-invalid': touched.email && !!errors.email },
                { 'input-touched': touched.email },
                { 'input-valid': touched.email && !errors.email },
              )}
            >
              <label htmlFor="email">Email</label>
              {!!email ? (
                <Input type="email" name="email" placeholder="Email" valid={true} defaultValue={email} />
              ) : (
                <Input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  autoComplete="email"
                  valid={touched.email && !errors.email}
                  invalid={touched.email && !!errors.email}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              )}
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.password },
                { 'input-invalid': touched.password && !!errors.password },
                { 'input-touched': touched.password },
                { 'input-valid': touched.password && !errors.password },
              )}
            >
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                autoComplete="new-password"
                valid={touched.password && !errors.password}
                invalid={touched.password && !!errors.password}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <FormFeedback>{errors.password}</FormFeedback>
            </FormGroup>
            <FormGroup className="row-terms">
              <CustomInput
                type="checkbox"
                id="accept"
                label="I agree to all statements included in"
                required
                valid={touched.accept && !errors.accept}
                invalid={touched.accept && !!errors.accept}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <p>
                  <NavLink target="_blank" href="#" className="fs-12 link-terms">
                    Terms of Use
                  </NavLink>{' '}
                  and{' '}
                  <NavLink target="_blank" href="#" className="fs-12 link-terms">
                    Privacy Policy
                  </NavLink>
                </p>
                <FormFeedback>{errors.accept}</FormFeedback>
              </CustomInput>
            </FormGroup>
            {error && <div className="text-center text-danger mb-1">{error}</div>}
            <FormGroup className="auth-actions">
              <Button type="submit" color="primary" className="btn-block" disabled={!isValid} onClick={handleSubmit}>
                Register for an account
              </Button>
              {!email && <span className="label-or">or</span>}
              {!email && (
                <a className="login-google text-center" href={googleLoginUrl}>
                  <GoogleIcon />
                  <span>Sign up with Google</span>
                </a>
              )}
            </FormGroup>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block"></div>
    </div>
  );
};
