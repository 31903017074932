import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import { ButtonDropdown, DropdownMenu, Row, Col, Button, InputGroup, Input } from 'reactstrap';

import './Filter.scss';
import styles from './Filter.module.scss';
import { FilterInput } from 'types/search-filter-types';

interface Props {
  onFilter: (filters: FilterInput) => void;
  filters: FilterInput;
}

export const Filter: React.FC<Props> = (props) => {
  const [checked, setChecked] = React.useState<FilterInput>(props.filters);
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  const close = () => setIsOpen(false);

  const onChange: React.ChangeEventHandler<any> = ({ target }) => {
    const [group, filter] = target.id.split('.');
    setChecked({
      ...checked,
      [group]: {
        ...checked[group],
        [filter]: !(checked[group] && checked[group][filter]),
      },
    });
  };
  const onReset = () => setChecked({});

  const applyFilters = React.useCallback(() => {
    setIsOpen(false);
    props.onFilter(checked);
  }, [checked, props]);

  return (
    <ButtonDropdown className="filter">
      <OutsideClickHandler onOutsideClick={close}>
        <Button className={styles.link_filter} color="default" onClick={toggle}>
          <i className={styles.icon_filter}></i> Filter
        </Button>
        <DropdownMenu className={cn({ [styles.show]: isOpen })}>
          <div className={`d-flex justify-content-between ${styles.filter_headline}`}>
            <strong className={styles.filter_heading}>Filter by</strong>
            <Button color="reset" onClick={onReset}>
              Reset filters
            </Button>
          </div>
          <Row>
            <Col>
              <strong className={styles.filter_title}>Project Type</strong>
              <InputGroup>
                <Input
                  type="checkbox"
                  id="type.RECURRING"
                  onChange={onChange}
                  checked={!!(checked['type'] && checked['type']['RECURRING'])}
                />
                <label htmlFor="type.RECURRING">Recurring</label>
              </InputGroup>
              <InputGroup>
                <Input
                  type="checkbox"
                  id="type.SERVICE_BLOCK"
                  onChange={onChange}
                  checked={!!(checked['type'] && checked['type']['SERVICE_BLOCK'])}
                />
                <label htmlFor="type.SERVICE_BLOCK">Service Block</label>
              </InputGroup>
            </Col>
            <Col>
              <strong className={styles.filter_title}>Project Status</strong>
              <InputGroup>
                <Input
                  type="checkbox"
                  id="status.ACTIVE"
                  checked={!!(checked['status'] && checked['status']['ACTIVE'])}
                  onChange={onChange}
                />
                <label htmlFor="status.ACTIVE">Active</label>
              </InputGroup>
              <InputGroup>
                <Input
                  type="checkbox"
                  id="status.ARCHIVED"
                  checked={!!(checked['status'] && checked['status']['ARCHIVED'])}
                  onChange={onChange}
                />
                <label htmlFor="status.ARCHIVED">Archived</label>
              </InputGroup>
            </Col>
          </Row>
          <Row className="filter-actions">
            <Col className="d-flex justify-content-between align-items-center">
              <InputGroup>
                {/* <Input type="checkbox" id="show-deactivated" />
                <label htmlFor="show-deactivated">Show deactivated projects</label> */}
              </InputGroup>
              <Button color="primary" className="text-nowrap" onClick={applyFilters}>
                Apply Filters
              </Button>
            </Col>
          </Row>
        </DropdownMenu>
      </OutsideClickHandler>
    </ButtonDropdown>
  );
};
