import React from 'react';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Loader } from 'components/Loader';
import { ProjectsTable } from 'components/ProjectsTable';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { Headline } from '../../components/Headline';
import { SubMenu } from '../../components/SubMenu';
import Select from 'react-select';
import { Button } from 'reactstrap';

const navigation = [
  {
    label: 'All Projects',
    href: `/projects`,
  },
  {
    label: 'MindTrust Projects',
    href: `/projects?show=mt`,
  },
  {
    label: 'My Projects',
    href: `/projects?show=my`,
  },
];

const options = [
  { label: 'Change Status', value: '' },
  { label: 'Duplicate', value: '' },
  { label: 'Archive', value: '' },
  { label: 'Export to CSV', value: '' },
  { label: 'Delete', value: '' },
];

export const ProjectsPage: React.FC = observer(() => {
  const { projectsStore, routerStore } = useAppStore();
  const { search } = routerStore.location;

  React.useEffect(() => {
    const { offset = 0, limit = 10 } = queryString.parse(search);
    projectsStore.loadProjects({ offset: Number(offset), limit: Number(limit) });
  }, [projectsStore, search]);

  return (
    <div>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <div className="mlr-sm">
        <Headline
          title="Projects"
          subTitle={`${projectsStore.projects.length} projects`}
          navigation={<SubMenu items={navigation} />}
          actions={
            <div>
              <Select
                className="select-container w-130 mr-10"
                classNamePrefix="select"
                options={options}
                placeholder="Actions"
                isMulti={false}
              />
              <Button className="mw-150" color="primary">
                Add Project
              </Button>
            </div>
          }
        />
      </div>
      {projectsStore.isLoading && (
        <div className="loader-fixed">
          <Loader />
        </div>
      )}
      <ProjectsTable
        total={projectsStore.total}
        projects={projectsStore.projects}
        onSearch={projectsStore.searchProjects}
        onFilter={projectsStore.applyFilters}
        onSort={projectsStore.sort}
      />
    </div>
  );
});
