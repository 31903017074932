import { action, computed, observable } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { RouterStore } from 'mobx-react-router';
import { ProjectsService } from 'store/services/projects-service';
import { RequestProject } from 'types/project-types';
import {
  PromoReview,
  StartProjectRequest1,
  StartProjectRequest2ProjectBased,
  StartProjectRequest3,
  StartProjectRequest5,
  StartStartProject2ResourceBased,
} from 'types/project-request-types';
import { requestProjectRoutes } from 'routing';
import { SelectOption } from 'types/select-types';
import { reviews } from './request-project-mocks';
import queryString from 'query-string';
import { pascalCase } from 'change-case';
import { RequestProjectQL_addProjectRequest } from 'types/gql-generated/RequestProjectQL';
import { ProjectRequestInput } from 'types/gql-generated';
import { cleanProjectRequest, saveProjectRequest } from 'utils/local-storage.utils';
import { readProjectRequest } from '../../utils/local-storage.utils';

export class RequestProjectStore {
  @observable private routerStore: RouterStore;
  private toastStore: ToastStore;
  private projectsService: ProjectsService;
  @observable private _solutionsSelectOptions: SelectOption<string>[] = [];
  @observable private _skillsSelectOptions: SelectOption<string>[] = [];
  @observable private _projectRequest: ProjectRequestInput = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    websiteUrl: null,
    type: null,
    projectSolutionName: null,
    skills: null,
    budgetFromUsd: null,
    budgetToUsd: null,
    whenToStart: null,
    additionalDetails: null,
  };
  @observable private _currentStep = 1;

  @computed
  get skillsSelectOptions() {
    return this._skillsSelectOptions;
  }

  @computed
  get urlSkills() {
    const { search } = this.routerStore.location;
    const { skills = null } = queryString.parse(search);
    if (Array.isArray(skills)) return skills.map((s) => pascalCase(s, { delimiter: ' ' }));
    return (skills ? [skills] : []).map((s) => pascalCase(s, { delimiter: ' ' }));
  }

  @computed
  get urlSolution() {
    const { search } = this.routerStore.location;
    const { solution = null } = queryString.parse(search);
    if (Array.isArray(solution)) return solution[0];
    return solution && pascalCase(solution, { delimiter: ' ' });
  }

  @computed
  get solutionsSelectOptions() {
    return this._solutionsSelectOptions;
  }

  @computed
  get projectRequest(): RequestProject {
    const { skills, projectSolutionName } = this._projectRequest;
    return {
      ...this._projectRequest,
      skills: skills || this.urlSkills,
      projectSolutionName: projectSolutionName || this.urlSolution,
    };
  }

  @computed
  get currentStep() {
    return this._currentStep;
  }

  constructor(projectsService: ProjectsService, routerStore: RouterStore, toastStore: ToastStore) {
    this.projectsService = projectsService;
    this.routerStore = routerStore;
    this.toastStore = toastStore;
    const projectRequestStorage = readProjectRequest();
    if (projectRequestStorage) {
      const { projectRequest = this.projectRequest, currentStep = this._currentStep } = projectRequestStorage;
      this._currentStep = Number(currentStep);
      this._projectRequest = projectRequest;
    }
  }

  @action
  getPromoReview = (): PromoReview => {
    return reviews[Math.floor(Math.random() * reviews.length)];
  };

  @action
  preloadSolutions = async () => {
    const result = await this.projectsService.loadProjectSolutions({ value: '', limit: 100 });
    this._solutionsSelectOptions = result.map(({ solution }) => ({ label: solution, value: solution }));
  };

  @action
  preloadProjectSkills = async () => {
    this._skillsSelectOptions = await this.loadProjectSkills();
  };

  @action
  loadProjectSkills = async (value: string | null = null) => {
    const result = await this.projectsService.loadProjectSkills({ value, limit: 100 });
    return result.map(({ skill }) => ({ label: skill, value: skill }));
  };

  @action
  firstStep = (params: StartProjectRequest1) => {
    this._projectRequest = { ...this._projectRequest, ...params };
    this._currentStep = 2;
    saveProjectRequest({ projectRequest: this._projectRequest, currentStep: this._currentStep });
    this.submit();
  };

  @action
  secondStepProjectBased = ({ projectSolutionName }: StartProjectRequest2ProjectBased) => {
    this._projectRequest = { ...this._projectRequest, projectSolutionName, skills: [] };
    this._currentStep = 3;
    saveProjectRequest({ projectRequest: this._projectRequest, currentStep: this._currentStep });
    this.routerStore.push(requestProjectRoutes.request(`${this._currentStep}`));
  };

  @action
  secondStepResourseBased = ({ skills }: StartStartProject2ResourceBased) => {
    this._projectRequest = { ...this._projectRequest, skills, projectSolutionName: null };
    this._currentStep = 3;
    saveProjectRequest({ projectRequest: this._projectRequest, currentStep: this._currentStep });
    this.routerStore.push(requestProjectRoutes.request(`${this._currentStep}`));
  };

  @action
  thirdStep = ({ type }: StartProjectRequest3) => {
    this._projectRequest = { ...this._projectRequest, type };
    this._currentStep = 4;
    const { search } = this.routerStore.location;
    saveProjectRequest({ projectRequest: this._projectRequest, currentStep: this._currentStep });
    this.routerStore.push(requestProjectRoutes.request(`${this._currentStep}${search}`));
  };

  @action
  fourthStep = ({ budget }: { budget: string | null }) => {
    if (budget) {
      const [budgetFromUsd, budgetToUsd] = budget.split('_');
      this._projectRequest = {
        ...this._projectRequest,
        budgetFromUsd: Number(budgetFromUsd),
        budgetToUsd: Number(budgetToUsd) || null,
      };
    }
    this._currentStep = 5;
    const { search } = this.routerStore.location;
    saveProjectRequest({ projectRequest: this._projectRequest, currentStep: this._currentStep });
    this.routerStore.push(requestProjectRoutes.request(`${this._currentStep}${search}`));
  };

  @action
  fifthStep = async ({ whenToStart, additionalDetails }: StartProjectRequest5) => {
    this._projectRequest = { ...this._projectRequest, whenToStart, additionalDetails };
    this._currentStep = 6;
    cleanProjectRequest();
    this.submit(true);
  };

  @action
  submit = async (showToast: boolean = false) => {
    try {
      const { createdAt, websiteUrl, ...request } = this._projectRequest as RequestProjectQL_addProjectRequest;
      const projectRequest = await this.projectsService.requestProject({
        ...request,
        websiteUrl: websiteUrl === '' ? null : websiteUrl,
      });
      this._projectRequest = projectRequest;
      showToast && this.toastStore.showSuccessMessage('Request has been successfully sent!');
      const { search } = this.routerStore.location;
      this.routerStore.push(requestProjectRoutes.request(`${this._currentStep}${search}`));
    } catch (error) {
      const { id, ...request } = this._projectRequest;
      this._projectRequest = request;
      cleanProjectRequest();
      this.toastStore.showErrorMessage(error.message);
    }
  };

  @action
  goBack = async () => {
    try {
      this._currentStep = this._currentStep - 1;
      const { search } = this.routerStore.location;
      if (this._currentStep === 2 && !this.projectRequest.projectSolutionName) {
        this.routerStore.push(`${requestProjectRoutes.request(this._currentStep)}/resources${search}`);
      } else {
        this.routerStore.push(requestProjectRoutes.request(`${this._currentStep}${search}`));
      }
    } catch (error) {
      this.toastStore.showErrorMessage(error.message);
    }
  };
}
