import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { Card, CardBody } from 'reactstrap';
import { SuccessPopup } from 'components/SuccessPopup';
import { ResetPasswordForm } from '../../components/Auth/ResetPasswordForm';
import { useAppStore } from '../../store/app-store.hook';
import { Helmet } from 'react-helmet';

export const ResetPasswordPage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const location = useLocation();
  const { resetPassword, error } = authStore;
  const [isSent, setIsSent] = React.useState(false);

  const onResetPassword = React.useCallback(
    async (password) => {
      const { access_token } = queryString.parse(location.search);
      if (await resetPassword(access_token as string, password)) setIsSent(true);
    },
    [location.search, resetPassword],
  );

  return (
    <>
      <Helmet>
        <title>Reset Password | MindTrust</title>
      </Helmet>
      <div>
        {!isSent && <ResetPasswordForm error={error} onResetPassword={onResetPassword} />}
        {isSent && (
          <Card className="w-23 success">
            <CardBody>
              <SuccessPopup />
              <Link to="/login">Go to login</Link>
            </CardBody>
          </Card>
        )}
      </div>
    </>
  );
});
